import {Nav, Navbar, NavDropdown, Container, Button, sidebarMenu, Spinner, Toast} from 'react-bootstrap';
import {Link, Outlet, useNavigate} from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import './style.css';
import  Cookie  from 'react-cookies';
import {FaHouseUser, FaWifi, FaPhone, FaCreditCard, FaQuestion, FaArrowRight, 
  FaUser, FaUserLock, FaUserPlus, FaAppStore} from 'react-icons/fa'
import { Whatsapp } from './Whatsapp';
import DataContext from './DataContext';
import { useWindowSize } from './components/windowSize';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
const Head = () =>{
  const [navColor, setNavColor] = useState('');
  const [textColor, setTextColor] = useState('white')
  const [variant, setVariant] = useState('dark');
  let style = { backgroundColor:navColor, color: textColor, variant, };
  let color = { color: style.color };
  const  [logo, setLogo] = useState({ height:'50px', width:'170px' })
  const [active, setActive] = useState(false)
  const navigate = useNavigate()
  const [show, setShow] = useState(true);
  const {width, height} = useContext(DataContext);
  
  useEffect(()=>{
  
  if(width <= 500){
    setNavColor('white');
     setTextColor('black');
    setVariant('light');
    setLogo({ height:'50px', width:'130px' })
  }
  }, [width])
    return (

      <>
        <div className='header'> 

           
    <Navbar collapseOnSelect fixed='top'  expand='lg'  bg="dark"  variant='dark'  className='flex-column' style={{  }}>
      <Container >
      <Navbar.Brand href="#" className=''><img src={require('./image/ux-logo.png')} alt="UXTECH" style={logo}/></Navbar.Brand>
       
        <Navbar.Toggle aria-controls="responsive-navbar-nav"  className='ms-auto' style={ {}}/>
        <Container className=''>
        
        <Navbar.Collapse id="responsive-navbar-nav" className='ms-auto'>
       
          <Nav className="ms-auto " id="navBox"   style={ { } }>
            <Nav.Link href='#' id="nav"
            onClick={ () =>  navigate('/dashboard')}
            >
              <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'>  <FaHouseUser size='20' className='' /> Dashboard </b>  
            </Button>
            </Nav.Link>
            <Nav.Link href='#' id="nav"
            onClick={ () =>  navigate('/buyAirtime')}
            >  
              <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaPhone/> Buy Airtime </b> 
            </Button>
            </Nav.Link>
            <Nav.Link href='#' id="nav"
            onClick={ () =>  navigate('/buyData')}
            > 
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaWifi /> Buy Data </b> 
            </Button>
             </Nav.Link>
             <Nav.Link href='#' id="nav"
            onClick={ () =>  navigate('/buyDataCard')}
            > 
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaWifi /> Buy Data Card </b> 
            </Button>
             </Nav.Link>
            <Nav.Link href="#" id="nav" 
            onClick={ () =>  navigate('/fund')}
            > 
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaCreditCard/> Fund </b> 
            </Button>
            </Nav.Link>
            {width >= 995 ?
            <NavDropdown title={
            <Button className='w-100 ' 
            variant='outline-light'> 
              <b className='float-start '> 
                <FaUser  /> More
              </b>
              </Button>}  id="collasible-nav-dropdown" >
          <NavDropdown.Item href="#"  className='w-100'
          onClick={ () =>  navigate('/app')}
          > 
          <FaAppStore/> Mobile App
          </NavDropdown.Item>
          <NavDropdown.Item href="#"  className='w-100'
          onClick={ () =>  navigate('/profile')}
          > 
          <FaUser size='10'/>
          User Profile
          </NavDropdown.Item>
          <NavDropdown.Item href="#"
          onClick={ () =>  navigate('/transactions')}
          >
          <FaQuestion size='10' />
            Transaction History
          </NavDropdown.Item>
          <NavDropdown.Item href="#"
          onClick={ () =>  navigate('/walletHistory')}
          >
          <FaQuestion size='10' />
            Wallet History
          </NavDropdown.Item>
          <NavDropdown.Item href="#"
          onClick={ () =>  navigate('/password')}
          >
            <FaUserLock size='10'/>
            Change PassWord
            </NavDropdown.Item>
          <NavDropdown.Divider />
          
          <NavDropdown.Item href="/agent">
            <FaUserPlus size='10'/>
             Become an Agent
          </NavDropdown.Item>
          <NavDropdown.Item href="/userToken">
            <FaQuestion size='10'/>
             Developer's API
          </NavDropdown.Item>
          <NavDropdown.Item onClick={()=>{
              setActive(true)
               setTimeout(()=> {
                setActive(false)
                Cookie.remove('user');
                Cookie.remove('userData');
                navigate('/')
               },2000)
              }
              }>
          <Button className='w-100' 
                variant='outline'> 
                {active && 
                    <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />}
                    { !active ?
                     <p className='float-start'>
                      <FaArrowRight /> Logout </p>:
                     <p className='float-start'>
                      <FaArrowRight />
                      Signing Out...</p> }
            </Button>
          </NavDropdown.Item>
          
        </NavDropdown>
        :
        
        
        <div>
        <Nav.Link href="#" id="nav" 
            onClick={ () =>  navigate('/app')}
            > 
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaAppStore/> Mobile App </b> 
            </Button>
            </Nav.Link>
        <Nav.Link href="#"  className='w-100'
          onClick={ () =>  navigate('/profile')}
          > 
          
          <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'><FaUser size='10'/> User Profile </b> 
            </Button>
          </Nav.Link>
          <Nav.Link href="#"
          onClick={ () =>  navigate('/transactions')}
          >
          
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaQuestion size='10' /> Transaction History </b> 
            </Button>
          </Nav.Link>
          <Nav.Link href="#"
          onClick={ () =>  navigate('/walletHistory')}
          >
          
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaQuestion size='10' /> Wallet History </b> 
            </Button>
          </Nav.Link>
          <Nav.Link href="#"
          onClick={ () =>  navigate('/password')}
          >
            
            <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaUserLock size='10'/> Change Password </b> 
            </Button>
            </Nav.Link>
          <NavDropdown.Divider />
          
          <Nav.Link href="/agent">
            
             <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaUserPlus size='10'/> Become an Agent </b> 
            </Button>
          </Nav.Link>
          <Nav.Link href="/userToken">
            
             <Button className='w-100' 
                variant='outline-light'> 
                <b className='float-start'> <FaQuestion size='10'/> Developer's API </b> 
            </Button>
          </Nav.Link>
          <Nav.Link  href='#' id="nav"  onClick={()=>{
              setActive(true)
               setTimeout(()=> {
                setActive(false)
                Cookie.remove('user');
                Cookie.remove('userData');
                navigate('/')
               },2000)
              }
              }> 
            <Button className='w-100' 
                variant='outline-light'> 
                {active && 
                    <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />}
                    { !active ?
                     <b className='float-start'>
                      <FaArrowRight /> Logout </b>:
                     <b className='float-start'>
                      <FaArrowRight />
                      Signing Out...</b> }
            </Button>
            </Nav.Link> 
          </div>
        
          }
              
            {/* <Nav.Link  href='#' id="nav"  onClick={()=>{
              setActive(true)
               setTimeout(()=> {
                setActive(false)
                Cookie.remove('user');
                Cookie.remove('userData');
                navigate('/')
               },2000)
              }
              }> 
            <Button className='w-100' 
                variant='outline-light'> 
                {active && 
                    <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />}
                    { !active ?
                     <b className='float-start'>
                      <FaArrowRight /> Logout </b>:
                     <b className='float-start'>
                      <FaArrowRight />
                      Signing Out...</b> }
            </Button>
            </Nav.Link> */}
           
          
            
      
          </Nav>
         
        </Navbar.Collapse>
        </Container>
        
      </Container>
    </Navbar>
    <Navbar bg='dark' variant='light'>
        
        </Navbar> 
          
        </div>
        <Whatsapp />
        </>
    )
} 

export default Head