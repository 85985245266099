import React from 'react'
import DataContext from './DataContext';
import { useContext, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import MyButton from './Button';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { createBankAccounts, userData } from './API';


export const Transfer = () => {
  const navigate = useNavigate();
const {getUser, setUser, deleteUser, setActiveUser} = useContext(DataContext);
const user = getUser('userData');
const [active, setActive] = useState(false);

let banks = user.data.bank.split(',').filter((bank) => bank !=  " " && bank);
let accountNumbers = user.data.accountNumber.split(',').filter((accountNumber) => accountNumber !=  " " && 
                                                                accountNumber !=  "_" &&
                                                                accountNumber.length > 0 && accountNumber);
// console.log(banks)
// console.log(user.data)
const handleCreateAccount =  async (e) => {
    e.preventDefault();
    setActive(true)
    const formData = new FormData(e.target);
 
    const data = new Object();
    formData.forEach((v, k) => {
      data[k] = v;
    })
    // console.log(data);
    if(data.bvn.length == 0 && data.nin.length == 0){
      
    }else{

      const response = await createBankAccounts(data)
      
        // console.log(response); 
        if(response.success){
          // setColor('black');
          // setMessage(response.message)
          // setActiveUser(response);
          // setUser('user', response);
          
          // setAlert(true)
          Swal.fire({
              text: response.message,
              icon: 'success',
              timer: 5000,
              toast: true,
              // showConfirmButton: false,
              position: 'top-end'
          });
          const getUserData = async () => {
            const uData = await userData()
            // console.log(uData)
                  if(uData.success){
                      setActiveUser(uData)
                      setUser('userData', uData);
                      // console.log(uData);
                  }else{
                      // console.log(uData);
                     Swal.fire('Accound Generated Successfully',
                                'Re-Login into Your account to access your new generated Account Numbers.', 'success')
                    setTimeout(()=> {
                      deleteUser('user');
                      navigate('/login?login')
                    }, 4000);
                  }
            
             }
             getUserData();
          // deleteUser('user');
          // deleteUser('userData');
          setTimeout(() => {
              setActive(false)
              navigate('/fund')
          }, 2000)
          // console.log(response)
      } else{
          Swal.fire({
              text: response.data.message ? response.data.message : response.message,
              icon: 'error',
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          // setMessage(response.message)
          // setColor('red');
          // setAlert(true)
          setTimeout(() => {
              setActive(false)
          }, 1000)
          // console.log(response)
      }
    }
    
                
}
useEffect(() => {
  document.title = "UXTECH | User Dedicated Account Numbers"
}, [])
  return (
   <div className='row justify-content-center'>
    <h2 className='text-center'> Transfer Option  </h2>
        {
          
          user.data.accountNumber.length == 0 && 
          <div className='col-lg-4 col-md-6 col-sm-8 col-xs-12'>
            <h6 className='text-center' style={{ fontWeight:700 }}> Create Personal Dedicated Accounts For Funding Your Wallet. </h6>
            <form id ="createForm" onSubmit={handleCreateAccount}>
                <FormGroup >
                    <Form.Label> Name:</Form.Label>
                    <Form.Control type='text'  value={user.data.name }
                    name='name'
                    // style={{ backgroundColor: 'black', color: 'white' }}
                    />
                </FormGroup>
                <FormGroup >
                    <Form.Label> Email:</Form.Label>
                    <Form.Control type='text' readOnly  value={user.data.email }
                    name='email'
                    // style={{ backgroundColor: 'black', color: 'white' }}
                    />
                </FormGroup>
                <FormGroup >
                    <Form.Label> Username:</Form.Label>
                    <Form.Control type='text' readOnly  value={user.data.username }
                    name='username'
                    // style={{ backgroundColor: 'black', color: 'white' }}
                    />
                </FormGroup>

                <small> <b className='text-muted'>KWC As Required by CBN</b> </small>
                <small> <p className='text-muted'> Provide your BVN or NIN  or both for Higher funding Limits.</p> </small>
                <FormGroup >
                    <Form.Label> BVN:</Form.Label>
                    <Form.Control type='text'  placeholder='Enter BVN'
                    name='bvn'
                    // style={{ backgroundColor: 'black', color: 'white' }}
                    />
                </FormGroup>
                <FormGroup >
                    <Form.Label> NIN:</Form.Label>
                    <Form.Control type='text'  placeholder='Enter NIN'
                    name='nin'
                    // style={{ backgroundColor: 'black', color: 'white' }}
                    />
                </FormGroup>

                <center>
                {/* <Button size="lg" variant="outline-dark" type="submit" className="btn-block-level w-100"   > */}
                <MyButton name="Create Account" active={active}/>
                </center>
            </form>
          </div>
        }
        {
          user.data.accountNumber.length > 0 &&
            <>
                <small className='text-muted text-center'> The following account numbers are linked to your wallet.
                Any transfer to any of these accounts will credit your wallet automatically. </small>
                {
                  banks.map((bank, i) => (
                      <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
                          style={{ margin:'auto', marginTop: '5%'}}
                          >
                          {/* <div > */}
                          <Form>
                            <h5>{bank}</h5>
                              <FormGroup >
                              <FormGroup >
                              <Form.Label> Bank Name:</Form.Label>
                              <Form.Control type='text' readOnly value={bank}
                              style={{ backgroundColor: 'black', color: 'white' }}
                              />
                              </FormGroup>
                              <Form.Label> Acc. Name:</Form.Label>
                              <Form.Control type='text' readOnly value={`UX-TECH-${user.data.accountName}`}
                              style={{ backgroundColor: 'black', color: 'white' }}
                              />
                              </FormGroup>

                              <FormGroup >
                              <Form.Label> Acc. Number:</Form.Label>
                              <Form.Control type='text' readOnly value={`${accountNumbers[i]}`}
                              style={{ backgroundColor: 'black', color: 'white' }}
                              />
                              </FormGroup>

                              
                              <small className=" text-muted" 
                                          style={{ color:'red', justifyItems: 'center'}}> 
                                          50 flat rate charge for all amounts.
                                          {/* <br/>
                                          <small> 50 flat rate charge plus 1% for transfers from ₦4,001 to 1,000,000,000!</small>  */}
                                          </small>

                          </Form>
                          {/* <h5>Bank: Wema Bank</h5>
                          <h5>Acc Name: UX-TECH-{user.data.username}</h5>
                          <h5>Account Number: {user.data.accountNumber}</h5> */}

                          {/* </div> */}

                        </div>
                  ))
                }
            </>
        }
   </div>

  )
}
