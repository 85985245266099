
import { createContext, useState } from "react";
import Cookie from 'react-cookies';
import { userData } from "./API";
import { useWindowSize } from "./components/windowSize";
import s1 from './image/slider.png';
import s2 from './image/slider1.png';
import ps1 from './image/pslidern.png';
import ps2 from './image/pslidern1.png';


const DataContext = createContext();

export const getUser = (name) =>{
    const data = Cookie.load(name);
     return data
 }  

 

// const user = 
export const DataProvider  = ({children}) => {

    const [width, height] = useWindowSize();
    

     

    const [sliders, setSliders] = useState([
        {   id: 1,
            src: s1,
            title: 'The First Picture',
            desc: 'UXTECH VTU POSTER',
            alt: 'POSTER' 
        },
        {
            id: 2,
            src: s2,
            title: 'The First Picture',
            desc: 'UXTECH VTU POSTER',
            alt: 'POSTER' 
        },
        
    ])

    const changeSlider = () => {
        if(width <= 500){
            setSliders([
                {   id: 1,
                    src: ps1,
                    title: 'The First Picture',
                    desc: 'UXTECH VTU POSTER',
                    alt: '' 
                },
                {
                    id: 2,
                    src: ps2,
                    title: 'The First Picture',
                    desc: 'UXTECH VTU POSTER',
                    alt: '' 
                },
                
            ]);
             
            
          }else{
            setSliders([
                {   id: 1,
                    src: s1,
                    title: 'The First Picture',
                    desc: 'UXTECH VTU POSTER',
                    alt: '' 
                },
                {
                    id: 2,
                    src: s2,
                    title: 'The First Picture',
                    desc: 'UXTECH VTU POSTER',
                    alt: '' 
                },
                
            ])
          }
    }

    const setUser = (name, value) => {
        Cookie.save(name, value, {
            maxAge: 60000,
            path: '/'
        });
    }

    const getUser = (name) =>{
       const data = Cookie.load(name);
        return data
    }  

    const deleteUser= (name) => {
        Cookie.remove(name);
    }
    const [activeUser, setActiveUser] = useState(null);
    
    
   

    return (
    <DataContext.Provider value={{ 
                    sliders, setSliders, changeSlider, width, 
                    activeUser, setActiveUser, getUser, setUser, deleteUser
        
     }} >
        {children   }
     </DataContext.Provider>
    )
}







export default DataContext;