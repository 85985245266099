import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Toast, ToastContainer } from 'react-bootstrap'
import MyButton from './Button'
import DataContext from './DataContext'
import Head from './Head'
import { activateAgent, userData } from './API';
import Swal from 'sweetalert2'

export const Agent = () => {
    const [active, setActive] = useState(false);
    const {getUser, deleteUser} = useContext(DataContext);
    const navigate = useNavigate();
    const user = getUser('user');
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('black')
    const style = { backgroundColor: 'white', borderRadius: '40px', color };

    useEffect(() => {
        if(!user){
          document.title = "UXTECH | Become an Agent";
            navigate('/login?login');
        
        }else{
          const getUserData = async () => {
            const uData = await userData()
            if(!uData.success){
                setMessage("User Session has Expired. Please Login again!")
                setColor('red');
                setShow(true);
               setTimeout(()=> {
                deleteUser('user');
                navigate('/login?login')
               }, 4000);
            }
            
             }

            getUserData()
        }
        }, [])


    const activate = async (e) => {
        e.preventDefault();
        setActive(true);
        const response = await activateAgent();
        if(response.status === 200){

          Swal.fire({
            text: response.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
          // setMessage(response.message);
            // setShow(true);
        }else{

          Swal.fire({
            text: response.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });  
          // setMessage(response.message);
            // setShow(true);
            
        }


        setTimeout(() => {
            setActive(false)
        }, 3000)
    }
  return (
    <div>
    <Head/>
    <h1 className="home text-center">Become an Agent</h1>
           
    <div className='col-lg-4 col-md-6 col-sm-8 col-xs-8 mx-auto '
     style={{ backgroundColor: 'whitesmoke', marginTop: '' }}>
       <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
        
        style={{ borderRadius: '40px', backgroundColor: 'white' }}
        >
          <Toast.Header className='  bg-light text-dark '  >
              <small className='me-auto'>Message</small>
          </Toast.Header>

          <Toast.Body className= 'text-center'
              style={style}>
                <p><b> {message} </b></p>
          </Toast.Body>
    </Toast>
        
        <h2 className="">Agent Benefits</h2>
      <p>
        As our agent, you will be given 5 naira discount for all data purchase
        and 2% discount for airtime purchase
      </p>

      <h2 className="py-2 text-2xl font-medium">Agent Fee</h2>
      <p>
        You only need to make a onetime payment of 2,000 naira only to upgrade
        your account to agent. Pay Once and continue to enjoy
        all our agent benefits
      </p>
        <Form onSubmit={activate}>
        <MyButton name="Activate Now" active={active}
        />
        </Form>
      
    </div>
    </div>

    
  )
}
