import {Outlet,Link } from 'react-router-dom';
import {Nav, Navbar, NavDropdown, Container, Button, sidebarMenu} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
const Header = () =>{
  const navigate = useNavigate();
  const [navColor, setNavColor] = useState('');
  const [textColor, setTextColor] = useState('white')
  const [variant, setVariant] = useState('dark');
  let style = { backgroundColor:navColor, color: textColor, variant, };
  let color = { color: style.color };
  const  [logo, setLogo] = useState({ height:'50px', width:'170px' })
  useEffect(()=>{
    let width = window.innerWidth;
  // console.log(width);
  if(width <= 500){
    setNavColor('white');
     setTextColor('black');
    setVariant('light');
    setLogo({ height:'50px', width:'100px' })
  }
  }, [])
    return (
        <div className='header'> 

           
    <Navbar collapseOnSelect expand  bg="dark" fixed='top' variant={style.variant}  className='flex-column' style={style}>
      <Container >
      <Navbar.Brand href="#" className=''><img src={require('./image/ux-logo.png')} alt="UXTECH" style={logo}/></Navbar.Brand>
       
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav"  className='ms-auto' style={style}/> */}
        <Container className=''>
        
        <Navbar.Collapse id="responsive-navbar-nav" className='ms-auto' >
       
          <Nav className="ms-auto " id="navBox"   style={ { } }>
           
            <Nav.Link href='#'
            onClick={ () =>  navigate('/login?login')}
            > <Button variant='outline-light' > Login </Button></Nav.Link>
            <Nav.Link  href='login?register'
             onClick={ () =>  navigate('/login?register')}
            > <Button variant='outline-light'> Register </Button></Nav.Link>
           
          </Nav>
         
        </Navbar.Collapse>
        </Container>
        
      </Container>
    </Navbar>
    
    {/* <Navbar bg='dark' variant='light'>
        
        </Navbar>  */}

      

            {/* <h1> Header </h1>
            <nav>
            <ul>
                <li> 
                    <Link to='/' className='nav-item'> Home  </Link>
                </li>
                <li>
                    <Link to='contact'>Contact Us</Link>
                </li>
                <li>
                    <Link to='about'>About</Link>
                </li>
            </ul>
            </nav>

            <Outlet /> */}
        </div>
    )
} 

export default Header