 import React, { useState } from 'react'
 import BuyAirtime from './buyAirtime';
 import Auth from './auth';
import BuyData from './buyData';
import BuyDataCard from './buyDataCard';
import VerifyIUC from './verifyiuc';
import VerifyMeter from './verifymeter';
import BuyCable from './buyCables';
import BuyMeterToken from './buyMeterToken';
// import "./css/style.css";
 const DocHome = ({doc}) => {
    
   return (

        <div 
        className='doc-endpoint-body'
        // style={{ 
            
        //  }}
        >
            <h3 className='mb-3 mt-2 '
            style={{ 
              fontWeight: 700,
              padding: '5px',
              backgroundColor: 'aliceblue',
              textAlign: 'center'
             }}
            >  Developers API Documentation</h3>

            {doc === 'auth' &&  <Auth /> }
            {doc === 'airtime' && <BuyAirtime />}
            {doc === 'data' && <BuyData />}
            {doc == 'dataPin' && <BuyDataCard />}
            {doc == 'verifyIUC' && <VerifyIUC />}
            {doc == 'verifyMeter' && <VerifyMeter />}
            {doc == 'cable' && <BuyCable />}
            {doc == 'electricity' && <BuyMeterToken />}

        </div>
   )
 }
 
 export default DocHome