import { FormGroup, Form, Toast} from "react-bootstrap";
import Cookie  from "react-cookies";
import { useState, useContext } from "react";
import {useParams, useLocation, Link, useNavigate } from 'react-router-dom'; 
import {login} from './API';
import Alert from './Alert';
import DataContext from "./DataContext";
import MyButton from "./Button";
import { useEffect } from "react";
import Swal from "sweetalert2";

export  const Login = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [active, setActive] = useState(false)
    const { setUser, setActiveUser, activeUser} = useContext(DataContext)
    const [input, setInput] = useState({});
    const [request, setRequest] = useState('login')
    const [status, setStatus] = useState(true)
    const {state} = useLocation('state');

    const [alert, setAlert] = useState(false)
    const [color, setColor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };
    const handleSubmit = (e) => {
        e.preventDefault()
        setActive(true)
        const email = e.target.email.value;
        const password = e.target.password.value;

        const formInputs = {
            email: email,
            password: password,
            platform: 'web',
            // version: '1.4'
        }

        
        const validate = async () => {
           
                const response =  await login(formInputs);
                // console.log(response.response);
                if(response.success){
                    // Cookie.save('user', response , {
                    //     path: '/',
                    //     maxAge: 6000,
                    //     secure: true,
                    // });
                     setUser('user', response);
                    setActiveUser(response);
                    console.log(response)
                    // localStorage.setItem('currentUser', JSON.stringify(response));
                //     setColor('black');
                //    setMessage(response.message);
                //     setAlert(true)
                Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    position: 'top-right'
                });
                    setTimeout(() => {
                        navigate('/dashboard')
                    }, 2000)
                }else if(response.code == 'ERR_NETWORK'){
                    Swal.fire({
                        text: "No Internet connection. Check you network and try again.",
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        position: 'top-right'
                    });
                   }
                else if(response.response.status == 500 || response.code == 'ERR_BAD_RESPONSE'){
                        Swal.fire({
                            text: "Server Error. Please contach support team to resolve this.",
                            icon: 'error',
                            timer: 5000,
                            toast: true,
                            showConfirmButton: false,
                             timerProgressBar: true,
                            position: 'top-right'
                        });
                    }else if(response.response.status === 401 || response.code == 'ERR_BAD_REQUEST'){
                        Swal.fire({
                            text: response.response.data.message,
                            icon: 'error',
                            timer: 5000,
                            toast: true,
                            showConfirmButton: false,
                            timerProgressBar: true,
                            position: 'top-right'
                        });
                    }
                else{
                        Swal.fire({
                            text:response.message,
                            icon: 'error',
                            timer: 5000,
                            toast: true,
                            showConfirmButton: false,
                            timerProgressBar: true,
                            position: 'top-right'
                        })
                    }
                    
              
                
                  setTimeout(() => {  
                    setActive(false)
                }, 1000)
           
        } 
        validate()
    }

   
 


    // console.log(inputs.name);
    // inputs.toString().length
    // const request = Cookie.load('request');
    useEffect(() => {
        document.title = "UXTECH | Login";
        // console.log(window.innerWidth);
        // console.log(window.innerHeight);
    }, [])

return (
    <div className="row justify-content-center mx-1"
    >
        <div className="card col-lg-4 col-md-6 col-sm-10 col-xs-12 pb-5 mt-3"
        style={{ backgroundColor: '' }}
        >
            <h2 className="text-center mt-2"> Sign In</h2>
            <Form onSubmit={handleSubmit} method='post' 
            // style={{ padding: '10px' }}
            >
                {/* <Alert show={alert} message = {message} /> */}
            <Toast onClose={() => setAlert(false)} show={alert} delay={3000} autohide className='w-100'
            
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
                <Toast.Header className='  bg-light text-dark '  >
                    <small className='me-auto'>Message</small>
                </Toast.Header>

                <Toast.Body className= 'text-center'
                    style={style}>
                        <p><b> {message} </b></p> 
                </Toast.Body>
            </Toast>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username or Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter username or email"
                    name='email'
                    required
                    // style={{ width: '400px' }}
                    />
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" 
                    name='password'
                    required
                    />
                </Form.Group>
            <Link to='/forget'> <Form.Label> Forgot Password </Form.Label> </Link>
                <center>
                {/* <Button size="lg" variant="outline-dark" type="submit" className="btn-block-level w-100"   > */}
                <MyButton name="Login" active={active}/>
                </center>
            </Form>
        </div>
    </div>
)
}
