import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import { FormGroup, Form, Button, Toast, Spinner } from 'react-bootstrap';
import DataContext from './DataContext';
import Head from './Head';
import { buyAirtime, getTransactions, getAirtime as fetchAirtime, getNetworkInitials as fetchNetworkInitials } from './API';
import MyButton from './Button';
import './style.css';
import { Receipt } from './Reciept';
import axios from 'axios';
import Swal from 'sweetalert2';


const Airtime = () => {
const navigate = useNavigate();
const [status, setStatus] = useState(false);
const {getUser, deleteUser} = useContext(DataContext)
const [amount, setAmount] = useState('');
const [phone, setPhone] = useState('')
const [show, setShow] = useState(false)
const [message, setMessage] = useState('')
const [active, setActive] = useState(false);
const user = getUser('user');
const [email, setEmail] = useState('');
const [type, setType] = useState('');
const [receiptData, setReceiptData] = useState(null);
const [showReceipt, setShowReceipt] = useState(false);
const [color, setColor] = useState('black');
const style = { backgroundColor:'white', borderRadius: '40px', color };
const [airtime, setAirtime] = useState(null);
const [validate, setValidate] = useState(true);
const [networkDetected, setNetworkDetected] = useState(null);
const [numberValid, setNumberValid] = useState(true);
const [network, setNetwork] = useState(null);
const [networkInitials, setNetworkInitials] = useState(null);


const getNetworkInitials = async () => {
    const response = await fetchNetworkInitials();
    setNetworkInitials(response);
}
const transactionHistory = async () => { 
     
    const data = user.data.email
    const history = await getTransactions((data));
   setReceiptData(history.data[0]);
//    console.log(history.data[0].date);
   
}

const getAirtime = async () => {
    const response = await fetchAirtime()
    setAirtime(response);
    // console.log(response)
}


useEffect(() => {
    document.title = 'UXTECH | Buy Airtime';
if(!user){
    navigate('/login?login');
}else {
    getNetworkInitials()
    getAirtime();
    // transactionHistory()
    // setShowReceipt(true)
    setEmail(user.data.email);
    setType(user.data.type);
    setStatus(true)
}
}, [])

const handleSubmit = async (e) => {
    setActive(true)
    e.preventDefault()
    const network =  JSON.parse(e.target.network.value)

    // if(amount < 50 ){
    //     Swal.fire({
    //         text: 'minimum recharge amount is 50 naira!',
    //         icon: 'warning',
    //         timer: 5000,
    //         toast: true,
    //         showConfirmButton: false,
    //         position: 'top-end'
    //     });
    //     // setMessage("minimum recharge amount is 50 naira!");
    //     // setColor('red');
    //     // setShow(true);
    //     setActive(false);
       
    // } else {
        if(phone.length < 11 || phone.length > 11){
            
            Swal.fire({
                text: 'Phone number cannot be less than or more than 11 digits!',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setPhone(phone.replace('+234', '0'));
            // setMessage("Phone number cannot be less than or more than 11 digits! " );
            // setColor('red');
            // setShow(true);
            setActive(false);

        }else if(amount < 50){
            
            Swal.fire({
                text: 'Minimum airtime topup is 50 naira!',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setPhone(phone.replace('+234', '0'));
            // setMessage("Phone number cannot be less than or more than 11 digits! " );
            // setColor('red');
            // setShow(true);
            setActive(false);

        }else{
            
            const data = {
                network: network.id,
                amount,
                mobile_number: phone.replace(/ /g, ''),
                platform: 'web'
        
            }
            // console.log(data);
            const response = await buyAirtime(data);
        
            // console.log(response);
            if(response.success){
        
                Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setColor('black');
                // setMessage(response.message);
                // setShow(true);
        
                transactionHistory()
              
                setTimeout(() => {
                    setShowReceipt(true)
                }, 1000);
                e.target.reset();
                
            }else if(response.status == 500){
                Swal.fire({
                    text: 'Request aborted. Please check transaction history before trying again to avoid double purchase.',
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setMessage("User Session has Expired. Please Login again!")
                // setColor('red');
                // setShow(true);
            //    setTimeout(()=> {
            //     deleteUser('user');
            //     navigate('/login?login')
            //    }, 3000);
                
            }else if(response.code === 'ERR_NETWORK' && !window.navigator.onLine){
                Swal.fire({
                    text: "No Internet Connection. Check your network and try again.",
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }else{
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setMessage(response.message);
                // setColor('red');
                // setShow(true);
            }
            setActive(false);
        }
        
    // }
    

}

useEffect(() => {
    // if(networkDetected){
        if(networkDetected === 'MTN' && phone.length > 10 && network === networkDetected
         || networkDetected === 'AIRTEL' && phone.length > 10 && network === networkDetected
         || networkDetected === 'GLO' && phone.length > 10 && network === networkDetected 
         || networkDetected === '9MOBILE' && phone.length > 10 && network === networkDetected
        ){
            setNumberValid(false);
        }else if(!validate && phone.length > 10){
            setNumberValid(false)
        }else if(networkDetected === null || networkDetected === 'UNKNOWN' || validate){
            setNumberValid(true);
        }
    // }
    if(phone.length < 3) {
        setNetworkDetected(null);
    }
     
}, [networkDetected, validate, phone, network])

    return !status ? ( <center> <h2>
      
        <Spinner className='d-flex mt-5'
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
        position="middle-center"
        />
        Loading... </h2></center>)
    
    : (
        <div className='home'>
        <Head/>
            <div className=' container-fluid row mt-2'>
                <div style={{ marginTop: '5px', backgroundColor: 'whitesmoke' }} 
                className='col-lg-8 col-md-8 '
                >
                <h2 className='text-center'> Buy Airtime </h2>
                <Form
                className='col-lg-8 col-md-8 col-sm-8 col-xs-10 mb-5' 
                style={{ margin: 'auto',  }}
                onSubmit={handleSubmit}
                >
                    <Receipt
                    show={showReceipt}
                    data={receiptData}
                    type={"purchase"}
                    onHide={() => {
                        setShowReceipt(false);
                        navigate('/dashboard')
                    }}
                    onClose={() => {
                        setShowReceipt(false)
                        setPhone('');
                        setAmount('');
                        setActive(false);
                    }}
                    />
                <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
                
                style={{ borderRadius: '40px', backgroundColor: 'white' }}
                > 
                    <Toast.Header className='  bg-light text-dark '  >
                    <small className='me-auto'>Message</small>
                    
                </Toast.Header>
                <Toast.Body className= 'text-center'
                    style={style}>
                        <p><b> {message} </b></p> </Toast.Body>
                </Toast>
                    <FormGroup>
                            <Form.Label> Network</Form.Label>
                            <Form.Select size='md' name = 'network'
                            onChange={(e) => {
                                setNetwork((JSON.parse(e.target.value).name).split(' ')[0])
                                // console.log((JSON.parse(e.target.value).name).split(' ')[0]);
                            }}
                            >
                                <option> select network </option>
                                    {airtime && airtime.map((airtime, index) =>  (
                                            
                                            <option value={JSON.stringify(airtime)} key={index} >
                                                {/* {setNetwork(ne)} */}
                                                {airtime.name}
                                            </option>
                                    
                                        
                                    )
                                    )}
                            </Form.Select>
                    </FormGroup>
                    
                    <FormGroup>
                            <Form.Label> Amount </Form.Label>
                            <Form.Control type='number' 
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder='enter amount to purchase'
                            />
                    </FormGroup>
                    <FormGroup>
                            <Form.Label> Amount You Will Be Charge</Form.Label>
                            <Form.Control type='number' disabled value={
                                amount && 
                                amount - (amount * .02)
                                // type === '2' || type === '0'?
                                // amount - (amount * 0.02) 
                                // : amount - (amount * .01)
                                }/>
                    </FormGroup>
                    <FormGroup>
                            <Form.Label> Phone Number</Form.Label>
                            <Form.Control
                            type='number'
                            placeholder='enter phone number'
                            value={phone}
                            maxLength={11}
                            pattern='[0-9]'
                            onChange={(e) => {
                                // e.target.value.length <= 11 && 
                                setPhone(e.target.value);
                                
                                if((e.target.value).length > 3 ){
                                    // console.log('now working')
                                networkInitials &&  networkInitials.map((network) => {
                                    var networkName = network.network;
                                    network.initials.map((initials) => {
                                        // console.log(e.target.value.slice(0, 4))
                                        e.target.value.slice(0, 4) === initials &&
                                        setNetworkDetected(networkName);
                                        e.target.value.slice(0, 5) === initials &&
                                        setNetworkDetected(networkName);
                                        
                                    })
                                    })
                                }
                                
                                setTimeout(() => {
                                    if(phone.length > 5 && networkDetected == null){
                                        setNetworkDetected('UNKNOW');
                                    }
                                }, 2000);
                            }}

                            />
                    </FormGroup>
                    <FormGroup>
                    <small>
                    <small className="float-start"> <b> Network is: {networkDetected && networkDetected} </b> </small>
                    </small>
                    <Form.Check type='switch'   label='Bypass!' reverse={true}
                            onClick={
                                (e) => setValidate(!validate)
                            }
                            />
                    </FormGroup>
                    <center>
                        <small><small> {phone.length > 10 && numberValid && <b className='text-danger'> Wrong Network Selected </b>}</small></small>
                    </center>
                {/* <center> <Button type='submit' variant='dark' style={{ marginTop: '20px', width: '100%' }}> Continue </Button> </center> */}
                <MyButton name={"Continue"} active={ active} disable={numberValid}/>
                </Form>
                </div>
                <div className='col-lg-4 col-md-4 '
                >
                    <div className=' justify-items-center mx-1'>
                        <h2 className='text-center'> Codes For Checking Balance </h2>

                        <div className=' text-center text-dark'
                        style={{ backgroundColor: 'lightyellow' }}
                        >
                            <h4> MTN </h4>
                            <p> *310# </p>
                        
                        </div>
                        <div className=' text-center text-white'
                        style={{ backgroundColor: 'red' }}
                        >
                            <h4> AIRTEL </h4>
                            <p>  *310#  </p>
                        </div>
                        <div className=' text-center text-white'
                        style={{ backgroundColor: 'darkgreen' }}
                        >
                            <h4> GLO </h4>
                            <p> *310#. </p>
                        </div>
                        <div className=' text-center text-dark'
                        style={{ backgroundColor: 'lightgreen' }}
                        >
                            <h4> 9MOBILE </h4>
                            <p> *310#  </p>
                        </div>
                    </div>
            </div>
            </div>
        </div>
    )
}
export default Airtime