import './style.css';
import { useState, useEffect, useContext } from 'react';
import {  Carousel, CardGroup, Card, Table, Button, Spinner} from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import DataContext from './DataContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getPlans as fetchPlans  } from './API';
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone } from 'react-icons/fa';



const Home = () =>{

    const navigate = useNavigate()
    const [prices, setPrices] = useState(null);
    const getPlans = async () =>{
        const response = await fetchPlans();
        setPrices(response);
        // console.log(response)
    }

   
    useEffect(()=> {
        document.title = "UXTECH - Virtual TopUp Services (VTU)"
        getPlans();
    }, [])

    const { sliders, changeSlider, width} = useContext(DataContext);
    
    
    useEffect(()=>{
       changeSlider()

    //    response();
       
        }, [width])
    return (
        <div>
        <Header/>
        <div className="home" > 
        <div
    style={{ 
      width:'100%',
      height: 40,
      backgroundColor: 'grey',
      padding:2
     }}
    >

        <Button 
        onClick={() => navigate('/app')}
        style={{ 
            justifyItems: 'end'
         }}
         className='float-end mx-2'
        variant='dark'
        
        > Download Mobile App 
        </Button>
    </div>
        <Carousel  interval='1000' fade>
            {sliders.map((slide, index) =>
            <Carousel.Item key={index}> 
            <center>
            <img 
            className='d-block w-100'
            src={slide.src}
            alt={slide.alt}
            height="500"
            />
            </center>
            
            </Carousel.Item>
            )}
      
    </Carousel>
    
    

    <div className='' style={{ backgroundColor: 'whitesmoke', marginBottom: '50px'  }}>

    
                <h2 style={{ marginTop: '50px', textAlign: 'center' }}> <b> <> OUR SERVICES </> </b></h2>
    <CardGroup style={{ marginTop: '50px', }}>
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }}  key={1}>
      <Card.Img  className=""variant="top" src={require("./image/airtime.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '50px' }}
      />
    </Card>
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }} key={2}>
      <Card.Img  className=""variant="top" src={require("./image/data.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '20px' }}
      />
    </Card>
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }} key={3}>
      <Card.Img  className=""variant="top" src={require("./image/cable.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '20px' }}
      />
    </Card>
    
    </CardGroup>

    <CardGroup style={{ marginTop: '50px', }}>
    
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }} key={4}>
      <Card.Img  className=""variant="top" src={require("./image/utility.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '20px' }}
      />
    </Card>
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }} key={5}>
      <Card.Img  className=""variant="top" src={require("./image/education.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '20px' }}
      />
    </Card>
    <Card as ="div" style={{ borderRadius: '40px', margin: '10px'  }} key={6}>
      <Card.Img  className=""variant="top" src={require("./image/airtimetocash.png")}
      width="50vh"
      height='300'
      style={{ borderRadius: '20px' }}
      />
    </Card>
    </CardGroup>
    </div>

    <div style={{ backgroundColor: 'whitesmoke' }}
    className='row mx-1 p-1'
    >
    <h2 style={{  textAlign:'center', marginTop:"50px"}}><b>  Our Data Prices </b> </h2>
    {/* <CardGroup> */}
       
        {prices ? prices.map((price, index) => {

            return(
         <Card  
         className='col-lg-6 col-md-6 col-12 my-2'
         style={{ 
            borderRadius: '30px', 
            borderWidth:'2px', 
            // margin: '1px', 
            // borderBlock:'10px', 
            borderColor: 'snowwhite'  
            }}
        key={index}
        >
                <Card.Img variant="top" src={price.img} style={{ width: '100px', height:'90px', margin:'auto' }} />
       
                <Card.Body>
                 <Card.Title style={{ margin:'auto', textAlign: 'center'  }}><b> {price.network} </b></Card.Title>
                 
                 <Table  responsive>
                 <thead> 
                 <tr key={price.network}>
                                <th>
                                    Size
                                </th>
                                <th>
                                    <center> Price </center>
                                    <center> agent/regular</center>
                                </th>
                                <th>
                                    Duration
                                </th>
                            </tr>
                </thead>
                <tbody>
                     { price.prices.map((plan, index) => {
                        return (
                            

                            <tr key = {index} >
                                <td >
                                    {plan.size}
                                </td>
                                <td >
                               <center>
                               ₦{plan.price.slice(1) - plan.discount}/
                                {plan.price}
                               </center>
                                </td>
                                <td >
                                    {plan.duration}
                                </td>
                            </tr>
                       
                        )
                     }
                     
                     )}
                </tbody>
                      </Table>
                 </Card.Body>
                <Card.Footer>
                <center> <small className="text-muted">  {price.network}</small> </center>
                </Card.Footer>
                </Card>
                )   }):
                <div className='d-flex justify-content-center'
                
                >
                    <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    // style={{ 
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     justifyItems: 'center',
                    //     alignSelf: 'center'
                    // }}
                    />
                </div>
                }

    {/* </CardGroup> */}
    </div>

    <div style={{ marginTop: '40px', backgroundColor: '', }} className='footerDiv'>
        <CardGroup style={{ }}>

        <Card  style={{ borderRadius: '40px', margin: '10px' }} >
                     <Card.Title>
                     <h2 style={{ textAlign: 'center', color: 'black', backgroundColor: 'whitesmoke'  }}> <b> BECOME AN AGENT/PARTNER </b></h2>
                     </Card.Title>
                    <Card.Body>
                        <p style={{ textAlign: 'center',  }}>
                        Our  Agents and Partners enjoy special discounts and offers for all our services.
                        </p>  
                           
                    </Card.Body>
        </Card>
       
        <Card  style={{ borderRadius: '40px', margin: '10px' }} >
                     <Card.Title>
                     <h2 style={{ textAlign: 'center', color: 'black', backgroundColor:  'whitesmoke' }}> <b> ABOUT US </b></h2>
                     </Card.Title>
                    <Card.Body>
                        <Card.Text>
                        UX-ELITE TECHNOLOGIES is a Tele-Communication Company that offers Virtual TopUp and subscription Services, 
                        Development of scalable web and mobile applications, Product/project Design, analysis and management,
                        DevOps and Cloud Services.
                         We are here to boost and enhance  your digital experience and presence!
                        </Card.Text>
                    </Card.Body>
        </Card>
        
        <Card  style={{ borderRadius: '40px', margin: '10px' }} >
            <Card.Title>
            <h2 style={{ textAlign: 'center', backgroundColor:  'whitesmoke' }}> <b><i> CONTACT US </i> </b></h2>
            </Card.Title>
            <Card.Body>
                <div>
                    <div className='p-3 my-2'
                    style={{ 
                        backgroundColor: 'whiteSmoke',
                        borderRadius: '20px',
                        // height: '150px',
                        padding: 3
                    }}
                    >
                    <p style={{ 
                        fontWeight: 800,
                        fontSize: 18
                     }}> SEND US A MAIL.</p>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaMailBulk fontSize='35' />
                        <p className='ms-2'> <i> support@uxtech.com.ng </i> </p>
                    </div>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaMailBulk fontSize='35' />
                        {/* <p className='ms-2'> <i> support@uxtech.com.ng </i> </p> */}
                        <p className='ms-2'> <i> support@uxtech.ng 
                            <small className='text-danger'
                            style={{ 
                                fontSize: 10
                             }}
                            >{` (Note: Delay in delivery)`}</small> </i> </p>
                    </div>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaMailBulk fontSize='35' />
                        <p className='ms-2'> <i> Team.uxtech@gmail.com </i> </p>
                        {/* Update node version */}
                    </div>
                    </div>
                    <div className='p-3 my-2'
                    style={{ 
                        backgroundColor: 'whiteSmoke',
                        borderRadius: '20px',
                        // height: '150px',
                        padding: 3
                    }}
                    >
                    <p style={{ 
                        fontWeight: 800,
                        fontSize: 18
                     }}> CHAT US ON WHATSAPP.</p>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaPhone fontSize='35' />
                        <p className='ms-2'> <i> 08129309721, 08138282448 </i>  </p>
                    </div>
                    </div>
                    <div className='p-3 my-2'
                    style={{ 
                        backgroundColor: 'whiteSmoke',
                        borderRadius: '20px',
                        // height: '150px',
                        padding: 3
                    }}
                    >
                    <p style={{ 
                        fontWeight: 800,
                        fontSize: 18
                     }}> FOLLOW US ON LINKEDIN.</p>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaLinkedin fontSize='35' />
                        <a href='https://www.linkedin.com/ux-tech'
                        style={{ 
                            color: 'red'
                        }}
                        >Visit our LinkedIn Page </a>
                    </div>
                    </div>
                    <div className='p-3 my-2'
                    style={{ 
                        backgroundColor: 'whiteSmoke',
                        borderRadius: '20px',
                        // height: '150px',
                        padding: 3
                    }}
                    >
                    <p style={{ 
                        fontWeight: 800,
                        fontSize: 18
                     }}> FOLLOW US ON FACEBOOK.</p>
                    <div className='d-flex align-items-end'>
                        {/* <Facebook fontSize='large'/> */}
                        <FaFacebook fontSize='35' />
                        <a href='https://www.facebook.com/uxtech.ng'
                        style={{ 
                            color: 'red'
                        }}
                        >Visit our Facebook Page </a>
                    </div>
                    </div>
                    
                </div>  
            </Card.Body>
        </Card>
        </CardGroup>
    </div>
            <Footer/>
        </div>
        </div>
    )
} 

export default Home