import React, {useEffect} from 'react'
import { Form, FormGroup } from 'react-bootstrap';
import Head from './Head';
import MyButton from './Button';

const AirtimeToCash = () => {

    useEffect(() => {
        document.title = "UXTECH | Airtime to Cash"
    }, [])
  return (
    <div className='mx-auto home'>
    <Head />
    
   

<center>
<h2 className='mt-2'> Airtime To Cash </h2>
<Form 
    className='col-lg-4 col-md-6 col-sm-8 col-xs-10 text-start mt-4'
    style={{ margin: '10%', marginTop: '5px', backgroundColor: 'whitesmoke'  }}
 >
    
    <Form.Label> Sender number </Form.Label>
    <FormGroup>
        <Form.Control type='number' placeholder='enter phone number' />
       
    </FormGroup>
    <FormGroup>
        <Form.Label> Amount to send </Form.Label>
        <Form.Control type='number' placeholder='minimum of 500 naira'/>
    </FormGroup>
    <Form.Label> How Do We Pay You </Form.Label>
    <FormGroup>
        <Form.Select>
            <option>
                Select Option
            </option>
            <option>
                Fund My Wallet
            </option>
            <option>
                Send to My Bank Account
            </option>
        </Form.Select>
    </FormGroup>
    <FormGroup>
        <Form.Label> Bank Name </Form.Label>
        <Form.Control type='text' placeholder='enter bank name' />
    </FormGroup>
    <FormGroup>
        <Form.Label> Account Name </Form.Label>
        <Form.Control type='text' placeholder='enter account name'/>
    </FormGroup>
    <FormGroup>
        <Form.Label> Account Number </Form.Label>
        <Form.Control type='number' placeholder='enter account number' />
    </FormGroup>
   
    <MyButton name={'Continue'} active={false} disable={true}/>
</Form>
</center>
</div>
  )
}

export default AirtimeToCash