
export const airtime = [
  { name: "MTN", id: 1 },
    { name: "MTN AWUF(400% Bonus)", id: 11 },
    { name: "AIRTEL", id: 4 },
    { name: "9MOBILE", id: 3 },
    { name: "GLO", id: 2 },
    { name: "GLO AWUF(500% Bonus)", id: 12 },
]

export const aidaElectricity = [
  {
    "provider_name":"Abuja Electricity Distribution Company [AEDC]",
  "provider_code":"aedc",
  "min_amount":500,
  "max_amount":50000,
  "ref_commission":null,
  "provider_info":null,
  "agent_percent":"0.355",
  "provider_image":"aedc-electricity.png"
  },
  {
    "provider_name":"Jos Electricity Distribution Company [JED]",
    "provider_code":"jed",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.40",
    "provider_image":"jos-electricity.png"
  },
  {
    "provider_name":"Port Harcourt Electricity Distribution Company [PHED]",
    "provider_code":"phed",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.80",
    "provider_image":"phed-electricity.png"
  },
  {
    "provider_name":"Kano Electricity Distribution Company [KEDCO]",
    "provider_code":"kedco",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.50",
    "provider_image":"kano-electricity.png"
  },
  {
    "provider_name":"Eko Electricity Distribution Company [PHCN]",
    "provider_code":"eedc",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.30",
    "provider_image":"ekedc.png"
  },
  {
    "provider_name":"Ikeja Electricity Distribution Company [IKEDC]",
    "provider_code":"ikedc",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.50",
    "provider_image":"ikeja.png"
  },
  {
    "provider_name":"Ibadan Electricity Distribution Company [IBEDC]",
    "provider_code":"ibedc",
    "min_amount":500,
    "max_amount":50000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.50",
    "provider_image":"ibedc-electricity.png"
  }
];

export const cables = [
  {
    "provider_name":"StarTimes",
    "provider_code":"startimes",
    "min_amount":50,
    "max_amount":100000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"1.00",
    "provider_image":"startimes.png"
  },
  {
    "provider_name":"GoTV"
    ,"provider_code":"gotv",
    "min_amount":50,
    "max_amount":100000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.55",
    "provider_image":"gotv.png"
  },
  {
    "provider_name":"DsTV",
    "provider_code":"dstv",
    "min_amount":50,
    "max_amount":100000,
    "ref_commission":null,
    "provider_info":null,
    "agent_percent":"0.55",
    "provider_image":"dstv.png"
  }
];
export const electricity = [
  {
    discoId: 1,
    discoName: 'IKEJA Electric'
  },
  {
    discoId: 2,
    discoName: 'EKO Electric'
  },
  {
    discoId: 3,
    discoName: 'ABUJA Electric'
  },
  {
    discoId: 4,
    disconName:'KANO Electricity'
  },
  {
    discoId : 5,
    disconName: 'KADUNA Electric'
  },
  {
    discoId : 6,
    disconName: 'JOS Electric'
  },
  {
    discoId: 7,
    discoName: 'PORTHARCOURT Electric'
  },
  {
    discoId: 8,
    discoName: 'IBADAN Electric'
  },
  {
    discoId: 9,
    disconame: 'ENUGU Electric'
  }
]

export const meter = [

  {
    meterName: 'prepaid',
    meterId :1,
  },
  {
    meterName: 'postpaid',
    meterId: 2
  }
];
export const prices = [
    {
      img: "/mtn.png",
      network: "MTN SME",
      provider: "superajaapi",
      networkID: 1,
      prices: [
        {
          size: "500.0MB",
          price: "₦125",
          duration: "30 days",
          planCode: 6,
          data_id: { d1: 6, d2: 6 },
        },
        {
          size: "1.0GB",
          price: "₦232",
          duration: "30 days",
          planCode: 7,
          data_id: { d1: 7, d2: 7 },
        },
        {
          size: "2.0GB",
          price: "₦455",
          duration: "30 days",
          planCode: 8,
          data_id: { d1: 8, d2: 8 },
        },
        {
          size: "3.0GB",
          price: "₦680",
          duration: "30 days",
          planCode: 44,
          data_id: { d1: 232, d2: 44 },
        },
  
        {
          size: "5.0GB",
          price: "₦1155",
          duration: "30 days",
          planCode: 11,
          data_id: { d1: 11, d2: 11 },
        },
        {
          size: "10.0GB",
          price: "₦2250",
          duration: "30 days",
          planCode: 220,
          data_id: { d1: 220, d2: 43 },
        },
      ],
    },
    {
      img: "/mtn.png",
      network: "MTN GIFTING",
      networkID: 1,
      prices: [
        {
          size: "500.0MB",
          price: "₦130",
          duration: "30 days",
          planCode: 216,
          data_id: {  d1  : 216 , d2  :214  }
        },
        {
          size: "1.0GB",
          price: "₦245",
          duration: "30 days",
          planCode: 217,
          data_id: {d1: 217, d2:  207}
        },
        {
          size: "2.0GB",
          price: "₦485",
          duration: "30 days",
          planCode: 209,
          data_id: {  d1: 209,  d2: 208 }
        },
        {
          size: "3.0GB",
          price: "₦730",
          duration: "30 days",
          planCode: 210,
          data_id:  {d1:  210, d2:  209}
        },
        {
          size: "5.0GB",
          price: "₦1230",
          duration: "30 days",
          planCode: 211,
          data_id: {d1: 211, d2:  210}
        },
        {
          size: "10.0GB",
          price: "₦2430",
          duration: "30 days",
          planCode: 43,
          data_id:  {d1:  43, d2: 211}
        },
        {
          size: "15.0GB",
          price: "₦4000",
          duration: "30 days",
          planCode: 52,
          data_id: { d1: 52, d2: 50 },
        },
        {
          size: "20.0GB",
          price: "₦5300",
          duration: "30 days",
          planCode: 50,
          data_id:  {d1:  50, d2: 212}
        },
        {
          size: "40.0GB",
          price: "₦11000",
          duration: "30 days",
          planCode: 51,
          data_id: { d1: 51, d2: 51 },
        },
        {
          size: "75.0GB",
          price: "₦19000",
          duration: "30 days",
          planCode: 218,
          data_id: { d1: 218, d2: "" },
        },
        {
          size: "100.0GB",
          price: "₦26000",
          duration: "30 days",
          planCode: 219,
          data_id: { d1: 219, d2: "" },
        },
      ],
    },
    {
      img: "/airtel.png",
      network: "AIRTEL",
      networkID: 4,
      prices: [
        {
          size: "100.0MB",
          price: "₦65",
          duration: "7 days",
          planCode: 225,
          data_id: { d1: 225, d2: "" },
        },
        {
          size: "300.0MB",
          price: "₦155",
          duration: "7 days",
          planCode: 226,
          data_id: { d1: 226, d2: "" },
        },
        {
          size: "500.0MB",
          price: "₦200",
          duration: "30 days",
          planCode: 221,
          data_id: { d1: 221, d2: "222" },
        },
        {
          size: "1.0GB",
          price: "₦290",
          duration: "30 days",
          planCode: 222,
          data_id: { d1: 222, d2: "223" },
        },
        {
          size: "2.0GB",
          price: "₦510",
          duration: "30 days",
          planCode: 223,
          data_id: { d1: 223, d2: "224" },
        },
        {
          size: "5.0GB",
          price: "₦1250",
          duration: "30 days",
          planCode: "",
          data_id: { d1: "", d2: "225" },
        },
        {
          size: "10.0GB",
          price: "₦2500",
          duration: "30 days",
          planCode: "248",
          data_id: { d1: "", d2: "226" },
        },
        {
          size: "15.0GB",
          price: "₦4000",
          duration: "30 days",
          planCode: "",
          data_id: { d1: "", d2: "227" },
        },
        {
          size: "20.0GB",
          price: "₦5000",
          duration: "30 days",
          planCode: "250",
          data_id: { d1: "", d2: "228" },
        },
      ],
    },
    {
      img: "/9mobile.png",
      networkID: 3,
      network: "9MOBILE SME",
      prices: [
        {
          size: "500.0MB",
          price: "₦225",
          duration: "30 days",
          planCode: 182,
          data_id: { d1: "", d2: "239"}
        },
        {
          size: "1.0GB",
          price: "₦335",
          duration: "30 days",
          planCode: 183,
          data_id: { d1: "", d2: "231"}
        },
        {
          size: "2.0GB",
          price: "₦650",
          duration: "30 days",
          planCode: 184,
          data_id: { d1: "", d2: "233"}
        },
        {
          size: "3.0GB",
          price: "₦920",
          duration: "30 days",
          planCode: 185,
          data_id: { d1: "", d2: "235"}
        },
        {
          size: "4.0GB",
          price: "₦1300",
          duration: "30 days",
          planCode: 186,
          data_id: { d1: "", d2: "236"}
        },
        {
          size: "5.0GB",
          price: "₦1650",
          duration: "30 days",
          planCode: 186,
          data_id: { d1: "", d2: "234"}
        },
        {
          size: "10GB",
          price: "₦3100",
          duration: "30 days",
          planCode: 187,
          data_id: { d1: "", d2: "237"}
        },
        {
          size: "15.0GB",
          price: "₦4500",
          duration: "30 days",
          planCode: 188,
          data_id: { d1: "", d2: "238"}
        },
        // {
        //   size: "40.0GB",
        //   price: "₦9100",
        //   duration: "30 days",
        //   planCode: 189,
        // },
        // {
        //   size: "75.0GB",
        //   price: "₦13,600",
        //   duration: "30 days",
        //   planCode: 190,
        // },
      ],
    },
  
    {
      img: "/glo.png",
      network: "GLO",
      provider: "superajaapi",
      networkID: 2,
      prices: [
        {
          size: "1.8GB(800MB+1GB NIGHT)",
          price: "₦500",
          duration: "15days(800MB+1GB NIGHT)",
          planCode: 194,
          data_id: {d1:"", d2:"194"}
        },
        {
          size: "3.9GB(1.9GB+2GB NIGHT)",
          price: "₦980",
          duration: "30days(1.9GB+2GB NIGHT)",
          planCode: 195,
          data_id:	{d1:"", d2:"195"}
        },
        {
          size: "7.5GB(3.5GB + 4GB night )",
          price: "₦1500",
          duration: "30days(3.5GB + 4GB night )",
          planCode: 196,
          data_id:	{d1:"", d2:"196"}
        },
        {
          size: "9.2GB(5.2GB +4GB night)",
          price: "₦1950",
          duration: "30days(5.2GB +4GB night)",
          planCode: 198,
          data_id:	{d1:"", d2:"197"}
        },
        {
          size: "10.8GB(6.8GB +4GB night)",
          price: "₦2500",
          duration: "30days(6.8GB +4GB night)",
          planCode: 199,
          data_id: 	{d1:"", d2:"198"}
        },
        {
          size: "14.0GB(9GB +1GB NIGHTPLAN)",
          price: "₦3000",
          duration: "30 days(9GB +1GB NIGHTPLAN)",
          planCode: 200,
          data_id:	{d1:"", d2:"199"}
        },
        {
          size: "18.0GB",
          price: "₦4000",
          duration: "30 days",
          planCode: 201,
          data_id:	{d1:"", d2:"200"}
        },
        {
          size: "29.5GB",
          price: "₦8000",
          duration: "30 days",
          planCode: 201,
          data_id:	{d1:"", d2:"202"}
        },
        {
          size: "50.0GB",
          price: "₦10000",
          duration: "30 days",
          planCode: 201,
          data_id:	{d1:"", d2:"203"}
        },
        {
          size: "93.0GB",
          price: "₦14000",
          duration: "30 days",
          planCode: 201,
          data_id:	{d1:"", d2:"204"}
        },
        
       
      ],
    },
  ];
 
  