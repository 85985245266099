import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { FormGroup, Form, Button, Toast, Spinner } from 'react-bootstrap';
import DataContext from './DataContext';
import { useContext } from 'react';
import Head from './Head';
import { buyData, getPlans, getTransactions, getNetworks, getNetworkInitials as fetchNetworkInitials } from './API';
import MyButton from './Button';
import Alert from './Alert';
import { Receipt } from './Reciept';
import './style.css'
import axios from 'axios';
import Swal from 'sweetalert2';




const Data = () => {
const navigate = useNavigate();
const [status, setStatus] = useState(false);
const {getUser, deleteUser} = useContext(DataContext);

// const [price, setPrice] =useState(response());
// console.log(price);
const user = getUser('user');
const [type, setType] = useState(null);
const [email, setEmail] = useState(null);
const [networkProviders, setNetworkProviders] = useState(null);
const [prices, setPrices] = useState(null);
const [plan, setPlan] = useState(null)
const [amount, setAmount] = useState(null);
const [discount, setDiscount] = useState(null);
const getPlanTypes = async () =>{
    const response = await getPlans();
    setPrices(response);
    // setPlan(response.data[0].prices);
    // setAmount(response.data[0].prices[0].price);
    // console.log(response)
}
const getNetworkProviders = async () =>{
    const response = await getNetworks();
    // console.log(response);
    setNetworkProviders(response);
}

const [ntwrk, setNtwrk] = useState(null)
const [network, setNetwork] = useState(null)
const [phone, setPhone] = useState('')
const [message, setMessage] = useState('');
const [active, setActive] = useState(false);
const [show, setShow] = useState(false);
const [receiptData, setReceiptData] = useState(null);
const [showReceipt, setShowReceipt] = useState(false);
const [color, setcolor] = useState('black');
const style = { backgroundColor: 'white', borderRadius: '40px', color };
const [networkId, setNetworkId] = useState(0);
const [validate, setValidate] = useState(true);
const [networkDetected, setNetworkDetected] = useState(null);
const [numberValid, setNumberValid] = useState(true);
const [networkInitials, setNetworkInitials] = useState(null);
const [myArray, setMyArray] = useState(null);
const testArray = [];
// const [buttonActive, setButtonActive] = useState(true);
// const [countDown, setCountDown] = useState(60);
// var timer = 0;
// setInterval(() => {
    
//     if(countDown !== 0){
//         timer = countDown - 1
//     }
//     setCountDown( timer);
//     // console.log(timer)
// }, 1000);
const getNetworkInitials = async () => {
    const response = await fetchNetworkInitials();
    // console.log(response);
    setNetworkInitials(response);
}
const transactionHistory = async () => { 
     
    const data = user.data.email
    const history = await getTransactions((data));
   setReceiptData(history.data[0]);
}

useEffect(() => {
    document.title = 'UXTECH | Buy Data';
    if(!user){
        navigate('/login?login');
    
    }else {
        getNetworkInitials();
        getNetworkProviders(); 
        getPlanTypes();
        // transactionHistory()
        // setShowReceipt(true)
        // console.log(receiptData)
        setType(user.data.type);
        setEmail(user.data.email);

        setStatus(true)
    }
    }, [])

const handleSelect = (e) => {
    const value = JSON.parse(e.target.value)
//    setNetwork(value.network);
   setNtwrk(value.network);
   
   setPlan('');
   setAmount('')
   setTimeout(() =>{
    setPlan(value.prices)
   }, 1000)
    
    // setAmount(value.prices[0].price)
}
const handleAmount = (e) => {
    const value = JSON.parse(e.target.value);
    const discount = value[4];
    const amount = value[0];
    setAmount(amount);
    setDiscount(discount);
}

const handleSubmit = async (e) => {
    e.preventDefault()
    setActive(true)
    
    const phoneLength = phone.length;
    // console.log(phoneLength);
    if(phoneLength > 11 || phoneLength < 11){
        Swal.fire({
            text: 'Phone number cannot be less than or more than 11 digits!',
            icon: 'warning',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        // setMessage("Phone number cannot be less than or more than 11 digits!");
        // setcolor('red');
        // setShow(true);
        setActive(false);
}else if(amount === null || amount === ''){
    Swal.fire({
        text: 'Please select a plan!',
        icon: 'warning',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    // setMessage("Please select a plan!");
    // setcolor('red');
    // setShow(true);
    setActive(false);
}else{
       
const network = JSON.parse(e.target.network.value);
const plan = JSON.parse(e.target.plan.value);
const data = 
    {
        network_id: network.networkID,
        mobile_number: phone,
       plan: plan[1],
       platform: 'web'
    }
   
    // console.log(data);
    // const buyNow = async () => {
        try {
            const response = await buyData(data);
            // console.log(response);
            if(response.success){
                Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setcolor('black');
                // setMessage(response.message)
                // setShow(true)
                transactionHistory()
               
                setTimeout(() => {
                    setShowReceipt(true)
                }, 1000);
                e.targe.reset();
                setActive(false);

                // console.log(response.message)
            }else{
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 8000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // console.log(response);
                // setMessage(response.message)
                // setcolor('red');
                // setShow(true)
                setTimeout(() => setActive(false), 11000);
                // console.log(response.message)
            }
           

        } catch (error) {
            
        }
        // finally{
            
        //     setActive(false)
        //     // console.log(active)
        // }
    // }

    // buyNow()
    // console.log(data) 
    }
   
}
    useEffect(() => {
        // if(networkDetected){
            if(networkDetected === 'MTN' && phone.length > 10 && network === networkDetected
             || networkDetected === 'AIRTEL' && phone.length > 10 && network === networkDetected
             || networkDetected === 'GLO' && phone.length > 10 && network === networkDetected 
             || networkDetected === '9MOBILE' && phone.length > 10 && network === networkDetected
            ){
                setNumberValid(false);
            }else if(!validate && phone.length > 10){
                setNumberValid(false)
            }else if(networkDetected === null || networkDetected === 'UNKNOWN' || validate){
                setNumberValid(true);
            }
        // }
        if(phone.length < 3) {
            setNetworkDetected(null);
        }
         
    }, [networkDetected, validate, phone, network])
    useEffect(() => {
        // console.log(myArray); 
    }, [myArray])
    return  !status ? ( <center> <h2>
      
        <Spinner className='d-flex mt-5'
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
        />
        Loading... </h2></center>)
        : (

    <div> 
            <Head/>
    
        <div className='home row m-lg-5 pt-lg-4  m-3 pt-5'>
        
        
       <div style={{ backgroundColor: "whitesmoke", }}
       className='col-lg-8 col-md-8 col-sm-12 col-xs-12 ps-lg-5 pe-lg-3 ps-xs-3 '
       >
        <h2 className='text-center'> Buy Data </h2>
        <center>
        <Form onSubmit={handleSubmit} 
        method='post'
        className='col-lg-6 col-md-8 col-sm-10 col-xs-12' 
        style={{ margin: '2%'  }} >
        <Receipt
            show={showReceipt}
            data={receiptData}
            type={"purchase"}
            onHide={() => {
                setShowReceipt(false);
                navigate('/dashboard')
                setActive(false)
            }}
            onClose={() => {
                setShowReceipt(false)
                setPhone('')
                setActive(false)
            }}
                
            />
        <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
        
        style={{ borderRadius: '40px', backgroundColor:'white' }}
        >
          <Toast.Header className='  bg-light text-dark '  >
              <small className='me-auto'>Message</small>
          </Toast.Header>
          <Toast.Body className= 'text-center'
            style={style}>
                 <p><b> {message} </b></p> </Toast.Body>
        </Toast>
            <FormGroup>
                <Form.Label className='float-start'> Network</Form.Label>
                <Form.Select
                onChange={(e) => {
                    setNetworkId(JSON.parse(e.target.value).id);
                    setNetwork(JSON.parse(e.target.value).network);
                    // console.log(JSON.parse(e.target.value).id);
                    setAmount('');
                    setPlan('');
                    var i = 0;
                    prices && prices.map((network, index) => {
                        if(networkId === network.networkID){
                            setMyArray({...myArray, [index]: network})
                            testArray  [i] = network;
                            i += 1;
                        // console.log(testArray);
                        }
                        
                    })
                }}
                >
                    <option> Select Network </option>
                    {
                        networkProviders && networkProviders.map((network, index) => (
                            <option value={JSON.stringify(network)} key={index}> {network.network} </option>
                        ))
                    }
                </Form.Select>
            </FormGroup>
            <FormGroup>
                    <Form.Label className='float-start'> Data Type</Form.Label>
                    <FormGroup>
                    <Form.Select onChange={handleSelect} name='network'
                     >
                    <option>
                        select data type
                    </option>
                    {networkId !== 0 && prices && prices.map((networks, index) =>  {
                            var status = networks.status 
                            
                        return networks.networkID == networkId && (
                                <option value={JSON.stringify(networks)} key={index}
                                disabled={status == 'false' ? false : true}
                                >
                                    { networks.network}
                                </option>
                           
                           )   
                    }
                        )}
                     </Form.Select>
                    </FormGroup>
                   
            </FormGroup>
            <FormGroup>
                    <Form.Label className='float-start'> Plan </Form.Label>
                    <Form.Select size='md' onChange={handleAmount}  name='plan'>
                        <option> select plan </option>
                               { plan && plan.map((plan, index) =>(
                                <option 
                                value={JSON.stringify([plan.price, plan.planCode, plan.data_id, plan.size, plan.discount ])}
                                key = {index}
                                
                                >
                                    {plan.size} for {plan.price}  {plan.duration}
                                </option>
                               )) }
                    </Form.Select>
            </FormGroup>
            <FormGroup>
                    <Form.Label className='float-start'> Amount </Form.Label>
                    <Form.Control type='text' value={amount} readOnly/>
            </FormGroup>
            <FormGroup>
                    <Form.Label className='float-start'> Amount You Will Be Charge</Form.Label>
                    <Form.Control type='text' readOnly value={ discount && Number(amount.slice(1)) - discount}
                    />
            </FormGroup>
            <FormGroup>
                   
                    <Form.Label className='float-start'> Phone Number </Form.Label>
                    <Form.Control type='number'  name='pone' value={phone} onChange={(e) => {

                        // e.target.value.length <= 11 && 
                        setPhone(e.target.value);
                       
                        // Logic for checking phone Carrier/Network.
                        if(e.target.value > 3){
                            networkInitials && networkInitials.map((network) => {
                               var networkName = network.network;
                               network.initials.map((initials) => {
                                // console.log(e.target.value.slice(0, 4))
                                e.target.value.slice(0, 4) === initials &&
                                 setNetworkDetected(networkName);
                                 e.target.value.slice(0, 5) === initials &&
                                 setNetworkDetected(networkName);
                                 
                               })
                            })
                        }
                        
                        setTimeout(() => {
                            if(phone.length > 5 && networkDetected === null){
                                setNetworkDetected('UNKNOW');
                            }
                        }, 2000);
                    }}
                    required
                    placeholder='enter phone number'
                    />
            </FormGroup>
            <FormGroup>
             <small>
             <small className="float-start"> <b> Network is: {networkDetected && networkDetected} </b> </small>
            </small>
            <Form.Check type='switch'   label='Bypass!' reverse={true}
                    onClick={
                        (e) => setValidate(!validate)
                    }
                    />
            </FormGroup>
            <small><small> {phone.length > 10 && numberValid && <b className='text-danger'> Wrong Network Selected </b>}</small></small>
            {/* <small className='text-danger'> 
                Verify phone number before clicking continue. 
            </small> */}
           {/* <center> <Button type='submit'  variant='dark' style={{ marginTop: '20px', width: '100%' }}> Continue </Button> </center> */}
            <MyButton name={"Continue"} active={active} disable={numberValid}/>
            
        </Form>
        </center>
        </div>
        <div className='col-lg-4 col-md-4 p-4'>
            <h2 className='text-center'> Codes For Data Balance </h2>

            <div className=' text-center text-dark'
            style={{ backgroundColor: 'lightyellow' }}
            >
                <h4> MTN </h4>
                <p> MTN SME:  *461*4# </p>
                <p> MTN CGIFTING: *312*4*7*4# </p>
                <p> MTN GIFTING/COUPON:  *323*4# </p>
            </div>
            <div className=' text-center text-white'
            style={{ backgroundColor: 'red' }}
            >
                <h4> AIRTEL </h4>
                <p> Airtel Cooperate Gifting:  *140#  </p>
            </div>
            <div className=' text-center text-white'
            style={{ backgroundColor: 'darkgreen' }}
            >
                <h4> GLO </h4>
                <p> GLO Cooperate Gifting:  *127*0#. </p>
            </div>
            <div className=' text-center text-dark'
            style={{ backgroundColor: 'lightgreen' }}
            >
                <h4> 9MOBILE </h4>
                <p> 9MOBILE GIFTING:  *228#  </p>
            </div>
        </div>
        </div>
    </div>
    )
}
export default Data