import React, { useEffect,  useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Form, FormGroup, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataContext from '../DataContext';
import Swal from 'sweetalert2';

const MonnifyCheckout = () => {
    const location = useLocation();
    const {getUser} = useContext(DataContext);
    const [amount, setAmount]  = useState();
    const navigate = useNavigate();
    const user = getUser('user');

    function loadMonnifySDK(){
        if(location.pathname == '/check-out' || location.pathname == '/fund'){
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://sdk.monnify.com/plugin/monnify.js";
            
            document.head.appendChild(script);
        }
    }
    function payWithMonnify(e) {
        e.preventDefault();

        window.MonnifySDK.initialize({
            amount: amount,
            currency: "NGN",
            reference: new String((new Date()).getTime()),
            customerFullName: user.data.name,
            customerEmail: user.data.email,
            apiKey: "MK_PROD_H9XYY3E27B",
            contractCode: "416343671879",
            paymentDescription: user.data.username +"Fund Wallet",
            // metadata: {
            //     "name": "Damilare",
            //     "age": 45
            // },
            // incomeSplitConfig: [{
            //     "subAccountCode": "MFY_SUB_342113621921",
            //     "feePercentage": 50,
            //     "splitAmount": 1900,
            //     "feeBearer": true
            // }, {
            //     "subAccountCode": "MFY_SUB_342113621922",
            //     "feePercentage": 50,
            //     "splitAmount": 2100,
            //     "feeBearer": true
            // }],
            // onLoadStart: () => {
            //     console.log("loading has started");
            // },
            // onLoadComplete: () => {
            //     console.log("SDK is UP");
            // },
            onComplete: function(response) {
                //Implement what happens when the transaction is completed.
               if( response.paymentStatus == 'PAID') {
                Swal.fire({
                    title: "Cancelled",
                    text: 'Payment Successful. You wallet will be credited immediately.',
                    // timer: 3000,
                    icon: 'success',
                    confirmButtonColor: 'black'
                }).then(data => {
                    navigate('/dashboard')
                })
               }else{
                Swal.fire({
                    title: "Cancelled",
                    text: response.responseMessage,
                    // timer: 3000,
                    icon: 'success',
                    confirmButtonColor: 'black',
                    
                })
               }
                // console.log(response);
            },
            onClose: function(data) {
                //Implement what should happen when the modal is closed here
                data.paymentStatus == 'USER_CANCELLED' &&
                 Swal.fire({
                    title: "Cancelled",
                    text: 'Payment Cancelled.',
                    timer: 3000,
                    icon: 'info',
                    // confirmButtonColor: 'black',
                    showCancelButton: false,
                    showConfirmButton: false
                })
                // console.log(data);
            }
        });
    }
    // console.log(window)
    useEffect(() => {
        document.title = "UXTECH | Payment Gateway";
        if(!user){
            navigate('/login?login');
        }
        loadMonnifySDK()

    }, [])
    // componentDidMount(() => {
    //     console.log('Component mounted')
    // })
return (
    <div>
        <div className=''>
        <Row  style={{ margin:'auto'}} className=" row">
             <h2 className="text-center my-3"> <u><b>Secure Payment GateWay</b> </u></h2>
             <h5 className="text-center text-muted my-3"> Pay using Monnify Secure Payment Gateway. </h5>
            <Col className="col-lg-4 col-md-6 col-sm-8 col-xs-10 align-items-center" style={{ margin:'auto', }}>
                <Form 
                // action="https://checkout.marasoftpay.live/"
                // target="_blank"
                method="post"
                onSubmit={payWithMonnify}
                >

                    <FormGroup>
                        <Form.Label> Amount </Form.Label>
                        <Form.Control type="number" 
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        name="amount"
                        placeholder="Enter amount you want to fund to your wallet"
                        required
                        >
                        </Form.Control>
                    </FormGroup>
                    <Button 
                    
                    type="submit" variant="dark" className="w-100" style={{ marginTop: '20px' }}> Pay Now </Button>
                    
                    <small className=" text-muted" 
                    style={{ color:'red', justifyItems: 'center'}}> 
                    50 flat rate charge! 
                    </small>
                </Form>
            </Col>
        </Row>
        </div>
    {/* <button type="button" onClick={payWithMonnify}>Pay With Monnify</button> */}
    </div>
)
}

export default MonnifyCheckout