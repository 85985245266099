import React from 'react'
import { useState, useEffect } from 'react'
import { Button, Col, Form, FormGroup, Row, Toast } from 'react-bootstrap'
import { resetPassword } from './API';
import MyButton from './Button';
import Header from './Header';

export const ForgetPassword = () => {

    const [email, setEmail] = useState('');
    const [active, setActive] = useState(false)
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState("");
    const [color, setColor] = useState('black');
    const [status, setStatus] = useState('400');

    const handleReset = async (e) => {
        e.preventDefault();
        setActive(true)
        console.log(email);
       const response = await resetPassword({email})
       if(response.success){
        setStatus('200');
        setColor('black');
        setMessage(response.message)
        setShow(true)
        setTimeout(() => {
            setActive(false)
            setEmail("")
        }, 5000);
       }else{
        setColor('red');
        setStatus('400');
        setMessage(response.message)
        setShow(true)
        setTimeout(() => {
            setActive(false)
            // setEmail("")
        }, 3000);
       }
        
        
    }
    useEffect(() => {
        document.title = "UXTECH | Forgot Password"
    }, [])
  return (
    <div>
    <Header/>
    <Row style={{  marginTop : '70px'  }}>
    <h2 
                style={{ marginTop: '70px' }}
                className='text-center'> Reset Password </h2>
    <Col className='home ' lg='4' md='6' sm='8' xs='10' style={{margin: 'auto', backgroundColor: 'whitesmoke' }}>   
        <Form  
         className='mx-auto' 
            onSubmit={handleReset}
        >
            <Toast onClose={() => setShow(false)} show={show} delay={15000} autohide className='w-100'
        
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
              <Toast.Header className='  bg-light text-dark w-100  '  >
                  <small className='text-muted text-center w-100'>
                    <b> { status === '200' ?
                     `Please wait for at least 1 minutes before trying again.` : 
                     ''} 
                     </b>
                </small> 
              </Toast.Header>

              <Toast.Body className= 'text-center'
                  style={{ backgroundColor: 'white', borderRadius: '40px', color }}>
                    <p><b> {message} </b></p>
              </Toast.Body>
        </Toast>
            <FormGroup className="mb-3">
                <Form.Label> Enter Your Email Address </Form.Label>
                <Form.Control
                type='email'
                required
                placeholder='enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                >

                </Form.Control>
            </FormGroup>
            <FormGroup>
                <MyButton name="Continue" active={active} /> 
            </FormGroup>
        </Form>
    </Col>
    </Row>
    </div>
  )
}
