import React from 'react'
import {Form, Row, Toast, Col, FormGroup, ToastContainer} from 'react-bootstrap';
import {useState, useEffect } from 'react';
import {useLocation, useSearchParams, useParams, useHref, Navigate} from 'react-router-dom'
import Header from './Header';
import MyButton from './Button';
import { setNewPassword } from './API';


const SetNewPassword = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [show, setShow] = useState("");
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState("");
    const [color, setColor] = useState('black');
    const [login, setLogin] = useState(false);
    const [forget, setForget] = useState(false);

    const key = useLocation().search.slice(5);
    const handleReset = async (e) => {
        e.preventDefault();
        setActive(true)

        if(password === confirmPassword){
            const data = {
                password,
                confirmPassword,
                key,
            }
            const response = await setNewPassword(data);
            if(response.success){
                setColor('black');
                setMessage(response.message)
                setShow(true)
                
                setTimeout(() => {
                    setLogin(true);
                }, 3000)
            }else{
                // console.log(response);
                setMessage(response.message)
                setColor('red');
            setShow(true);
            // console.log(data)
                
                setTimeout(() => {
                   setForget(true)
                    
                }, 3000)
            }
        }else{
            setMessage("New Password does not match with Confirm Password.")
            setColor('red');
            setShow(true)
        setTimeout(() => {
            setActive(false)
        }, 3000)
        }
        
        
    }
    useEffect(() => {
        document.title = "UXTECH | Set New Password"
    }, [])
  return (
    <div>
    <Header/>
    {forget? <Navigate to='/forget' /> : login? <Navigate to='/login?login' /> : ""}
    <Row style={{ marginTop : '70px' }}>
    <h2 
                style={{ marginTop: '70px' }}
                className='text-center'> Set New Password </h2>
    <Col className='' lg='4' md='6' sm='8' xs='10' style={{margin: 'auto', 
        background: 'whitesmoke'
        }}> 
        <Form  
         className='' 
            onSubmit={handleReset}
        >
            <ToastContainer className='p-2 w-100' position='top-center'>
            <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide className='w-100'
        
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
              <Toast.Header className='  bg-light text-dark '  >
                  <small className='me-auto'>Message</small>
              </Toast.Header>

              <Toast.Body className= 'text-center'
                  style={{ backgroundColor: 'white', borderRadius: '40px', color }}>
                    <p><b> {message} </b></p>
              </Toast.Body>
        </Toast>
        </ToastContainer>
            <FormGroup className="mb-3">
                <Form.Label> Password </Form.Label>
                <Form.Control
                type='password'
                placeholder='enter new password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> Confirm Password </Form.Label>
                <Form.Control
                type='password'
                placeholder='confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                >

                </Form.Control>
            </FormGroup>
            <FormGroup>
                <MyButton name="Continue" active={active} /> 
            </FormGroup>
        </Form>
    </Col>
    </Row>
    </div>
  )
}

export default SetNewPassword