import React from 'react'
import { useEffect, useContext, useState } from 'react';
import {Form, FormGroup, Toast, Row, Col, Button } from 'react-bootstrap';
import { ChangePassword, userData } from './API';
import MyButton from './Button';
import Head from './Head';
import DataContext from './DataContext';
import { useNavigate } from 'react-router-dom';

export const Password = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("")
    const [active, setActive] = useState(false);
    const [old, setOld] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirm, setConfirm]  = useState('');
    const {getUser, deleteUser} = useContext(DataContext)
    const [color, setColor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };



    useEffect(() => {
        const user = getUser('user');
        if(!user){
            navigate('/login?login');
        
        }else{
            const getUserData = async () => {
                const uData = await userData()
                if(!uData.success){
                    setMessage("User Session has Expired. Please Login again!")
                    setColor('red');
                    setShow(true);
                   setTimeout(()=> {
                    deleteUser('user');
                    navigate('/login?login')
                   }, 4000);
                }
                
                 }
    
                getUserData()
        }
        }, [])

    const handleUpdate = async (e) => {
        setActive(true)
        e.preventDefault();
        
        const data = {
            old_pass: old,
            new_pass: newPassword,
            confirm_pass: confirm
        }
        
        const response = await ChangePassword(data);
        console.log(response);

        if(response.status === 200 || response.status === 202){
            setMessage(response.message);
            setColor('black');
            setShow(true)
            setOld("");
            setNewPassword("");
            setConfirm("");

            setTimeout(() => {
                setActive(false)
            }, 3000)
        }else if(response.status === 300 || response.status === 303){
            setMessage( response.message)
            setColor('red');
            setShow(true)
            setTimeout(() => {
                setActive(false)
            }, 3000)

        }else{
            setColor('red');
            setMessage(response.message)
            setShow(true)
            setTimeout(() => {
                setActive(false)
            }, 3000)
        }
        setActive(false)

       
        }
        

    // useEffect(() => {
    //     console.log(old);
    // }, [old])
    useEffect(() => {
        document.title = "UXTECH | Change Password"
    }, [])
  return (
    
    <div>
    <Head/>
    <Row style={{  marginTop : '70px'  }}>
    <h2 
                style={{ marginTop: '70px' }}
                className='text-center'> Change Password </h2>
    <Col className='' lg='4' md='6' sm='8' xs='10' style={{margin: 'auto', }}>
        <Form  
        style={{ backgroundColor: 'whitesmoke' }}
         className='mx-auto' 
            onSubmit={handleUpdate}
        >
            <Toast onClose={() => setShow(false)} show={show} delay={15000} autohide className='w-100'
        
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
              <Toast.Header className='bg-light text-dark '  >
                  <small className='me-auto'>Message</small>
              </Toast.Header>

              <Toast.Body className= { `text-center `}
                  style={style }>
                    <p><b> {message} </b></p>
              </Toast.Body>
        </Toast>
            <FormGroup className="mb-3">
                <Form.Label> Old Password </Form.Label>
                <Form.Control
                type='password'
                placeholder='enter old password'
                value={old}
                onChange={(e) => setOld(e.target.value)} 
                required
                
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> New Password </Form.Label>
                <Form.Control
                type='password'
                placeholder='enter new password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> Confirm Password </Form.Label>
                <Form.Control
                type='password'
                placeholder='confirm password'
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                required
                
                >

                </Form.Control>
            </FormGroup>
            
            <FormGroup>
                <MyButton name="Submit" active={active} /> 
            </FormGroup>
        </Form>
    </Col>
    </Row>
    </div>
  )
}
