import React, {useState, useEffect} from 'react'
import DocHome from '../apidocumentation'
import "../apidocumentation/css/style.css";
import {FaCheck, FaKey, FaLightbulb, FaRegLightbulb, FaSimCard, FaTv, FaUser, FaUserLock, FaWifi} from 'react-icons/fa'
const Documentation = () => {
  const [active, setActive] = useState('auth')

  useEffect(() => {
    window.document.title = "UXTECH- API Documenation"
  },[])
  useEffect(() => {
    setTimeout(() => window.scrollTo(0,0), 500);
  }, [active])
  return (
    <div
    id='contents'
     >
        <div
        id='side_bar'
        className='card'
        // style={}
        > 
            <span className='d-flex justify-content-around mt-2'>
              <img src='logo.png' width={50} height={30} alt='Logo' />
              <h5> <u><i>www.uxtech.ng</i></u> </h5>
            </span>
            <h4 className='text-center mt-1'> Available EndPoints</h4>
            <hr/>
            <ul className='p-2'>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'auth' && ('active text-light bg-dark')}`}
                // style={{ 
                //     fontSize: 15
                //  }}
                 onClick={() => setActive('auth')}
                >
                    <FaUserLock /> Authentication
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'airtime' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('airtime')}
                >
                    <FaSimCard /> Buy Airtime
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'data' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('data')}
                >
                   <FaWifi/> Buy Data
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'dataPin' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('dataPin')}
                >
                   <FaWifi /> Buy Data Pin/Card
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'verifyIUC' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('verifyIUC')}
                >
                  <FaCheck />  Verify IUC Number
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'cable' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('cable')}
                >
                  <FaTv />  Buy Cables
                </li>
                
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'verifyMeter' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('verifyMeter')}
                >
                  <FaRegLightbulb/>  Verify Meter Number
                </li>
                <li className={`doc-side-bar-list btn btn-lg text-start btn-light form-control p-2 mb-2 ${active == 'electricity' && ('active text-light bg-dark')}`}
                style={{ 
                    fontSize: 15
                 }}
                 onClick={() => setActive('electricity')}
                >
                   <FaLightbulb /> Buy Electricity Token
                </li>
            </ul>
        </div>

        <div 
        className='container ps-2'
        style={{ 
          // position: 'fixed',
          marginLeft: '250px',
          width: 'fit-content',
          overflow: 'scroll',
         }}
        >
            <DocHome doc={active} />
        </div>
        
     </div>
     
    
    // <div
    // className='container-fluid'
    // >
    //  <div
    //  className="container-fluid text-center my-4"
    //  style={{ 
    //     backgroundColor: 'white'
    //   }}
    //  >
    //        <h1>API Documentation</h1>
    //     <div
    //     className="container-fluid text-center my-4"
    //     style={{ 
    //         backgroundColor: 'whitesmoke'
    //     }}
    //     >
    //         <h3> BUY AIRTIME </h3>
    //         <h5> API ENDPOINT URL FOR PURCHASING AIRTIME </h5>
    //         <img src='apiImages/airtimeURL.jpg' alt='AIRTIME ENDPOINT URL' />
    //     </div>
    //     <div
    //     className="container-fluid text-center my-4"
    //     style={{ 
    //         backgroundColor: 'whitesmoke'
    //     }}
    //     >
    //            <h2> BUY DATA </h2>
    //         <h3> API ENDPOINT URL FOR GETTING ALL DATA PLANS </h3>
    //         <h6 className=''
    //         style={{ 
    //             color: 'red'
    //          }}
    //         > https://uxtech-api.herokuapp.com/api/v1/dataPlans </h6>

    //         <h3> API ENDPOINT URL FOR PURCHASING DATA </h3>
    //         <img src='apiImages/airtimeURL.jpg' alt='AIRTIME ENDPOINT URL' />
    //   </div>
        
    //  </div>
    // </div>
  )
}

export default Documentation