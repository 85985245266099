import React from 'react'
import reactWhatsapp from 'react-whatsapp';
import {FaWhatsapp} from 'react-icons/fa'
import FloatingWhatsApp from 'react-floating-whatsapp'
import Cookie from 'react-cookies'
import { useState, useEffect } from 'react';

export const Whatsapp = () => {
    const user = Cookie.load('user');

    useEffect(() => {

    }, [user])
  return (
    // <reactWhatsapp number="+2348138282448" >
    // <reactWhatsapp number="+2348138282448">
    //     <FaWhatsapp size="40" className='float-end'/>Contact
    // </reactWhatsapp>
    // <ReactWhatsapp number="1-212-736-5000" message="Hello World!!!" />
    <FloatingWhatsApp phoneNumber="+2348138282448" accountName="UXTECH" avatar={require("./image/logo.png")}
    chatMessage={`Hi ${user && user.data.name.split(" ")[0] || ""}, How can we help you?`} 
    statusMessage = "We provide support 24/7"
    />
  )
}
