import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Toast } from 'react-bootstrap'
import Swal from 'sweetalert2'

const MobileApp = () => {
    // const [show, setShow] = React.useState(false)
    useEffect(() => {
        document.title = "UXTECH | Download Mobile Application"
    }, [])
  return (
   <>
   <center>
   {/* <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide className=''
        
        style={{ borderRadius: '40px', backgroundColor: 'white' }}
        > 
            <Toast.Header className='  bg-light text-dark '  >
              <small className='me-auto'>Message</small>
              
          </Toast.Header>
          <Toast.Body className= 'text-center'
            style={{ backgroundColor:'white', borderRadius: '40px', color: 'black' }}>
                 <p><b> Please check back later, IOS file will be availble soon. Thank You. </b></p> </Toast.Body>
        </Toast> */}
         <div
    className='mx-2 mt-5'
    style={{ 
        backgroundColor: 'whitesmoke'
     }}
    >
        <img src={require('./image/logo.png')}/>
        <h2>
            Download our mobile application for your device.
        </h2>
        <Row>
            <Col>
            <h4> Android Devices (Android) </h4>
            </Col>
            <Col>
            {/* <a href='www.admin.uxtech.ng/arewa.pdf' target="_blank" download>
                <Button>
                    <i className="fas fa-download"/>
                    Download
                </Button>
            </a> */}

            <Link to="/app/uxtechApp.apk" target="_blank" download>
                
            <Button variant='dark'>
                    Download
                </Button>
            </Link>
            </Col>
        </Row>
        <Row
        className='mt-2'
        >
            <Col>
            <h4> iPhone Devices  (IOS) </h4>
            </Col>
            <Col>
                <Button
                variant='dark'
                onClick={() => 
                    // setShow(true)
                    Swal.fire({
                        text: "Please check back later, IOS file will be available for download soon. Thank You.",
                        icon: 'info',
                        iconColor: 'black',
                        confirmButtonColor: 'black'
                    })
                }
                >
                    <i className="fas fa-download"/>
                    Download
                </Button>
            </Col>
        </Row>
    </div>
    </center>
   </>
  )
}

export default MobileApp