import React from 'react'
import { useState, useContext, useEffect } from 'react';
import {Form, FormGroup, Toast, Row, Col, Button, Spinner } from 'react-bootstrap';
import MyButton from './Button';
import Head from './Head';
import DataContext from './DataContext';
import { updateProfile, userData } from './API';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("")
    const [active, setActive] = useState(false);
    const { getUser, deleteUser} = useContext(DataContext);
    const user = getUser('user')
    const navigate = useNavigate();
    // const [user, setUser] = useState(activeUser.data || "");
    const [name, setname] = useState();
    const [email, setEmail] = useState( "");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [bvn, setbvn] = useState("");
    const [nin, setnin] = useState("");
    const [bvnOriginal, setbvnOriginal] =  useState("");
    const [ninOriginal, setninOriginal] =  useState("");
    const [showBVNandNIN, setShowBVNandNIN] =  useState(null);
    const [edit, setEdit] = useState(true);
    // const [buttonName, setButtonName] = useState('Click to Edit')
    const [buttonName, setButtonName] = useState('Submit')
    const [buttonType, setButtonType] = useState("button");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [color, setColor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };
    const [update, setUpdate] = useState(null);

    const getUserData = async () => {
        const uData = await userData()
        if(!uData.success){
            setMessage("User Session has Expired. Please Login again!")
            setColor('red');
            setShow(true);
           setTimeout(()=> {
            deleteUser('user');
            navigate('/login?login')
           }, 4000);
        }else{
            setname(uData.data.name)
            setEmail(uData.data.email);
            setPhone(uData.data.phone);
            setUsername(uData.data.username);
            setbvn(uData.data.bvn);
            setnin(uData.data.nin);
            setbvnOriginal(uData.data.bvn);
            setninOriginal(uData.data.nin);
            if(uData.data.bvn.length > 0 || uData.data.nin.length > 0){
                setShowBVNandNIN(true);
                setEdit(false)
                setButtonName('Click to Edit')
            }
        }
        
         }


    useEffect(() => {
        document.title = "UXTECH | User Profile";
            if(!user){
                navigate('/login?login');
            
            }else{
                    getUserData()
            }
        
    },[])

    const handleUpdate = async (e) => {
        e.preventDefault()
        setButtonDisabled(true);
        if(buttonName === 'Submit'){
            const data = {
                email,
                name,
                phone,
                username,
                bvn,
                nin
            }
            const response = await updateProfile(data);
            // console.log(response);
            setMessage(response.message);
            setShow(true);
            getUserData();
            setTimeout(() =>{setButtonName('Click to Edit')
            setUpdate('');
            
            }, 3000)
            setButtonDisabled(false);
            

            // console.log(data);
        }
        setButtonDisabled(false);
    }

    useEffect(() => {
       if(bvn.length === 0  && nin.length  === 0){
        setShowBVNandNIN(false);
       }
    }, [bvn, nin])
  return (
    
    <Row style={{  marginTop : '70px' }} className='mx-1'>
    <Head/>
    <div className='m-1'>
    <h2 
                style={{ marginTop: '70px' }}
                className='text-center'> User Profile 
    </h2>
    <h4 className='mx-4 text-center'>{!showBVNandNIN ?  
    'Update your profile with your BVN or NIN.' 
    :
    'Click Edit Profile to update your profile details.'
    } </h4>
   
    </div>
     {/* <FormGroup>
                <Button className='w-50 float-end' variant='dark'
                 type={buttonType} onClick={() => {
                    setEdit(true);
                    setTimeout(() =>{setButtonName('Submit')}, 1000)
                    setButtonType('submit')
                }}>
                {'Click to Update Profile'}    
                </Button> 
            </FormGroup> */}
    <Col lg='4' md='6' sm='8' xs='10' style={{margin: 'auto',
      }}> 
        <Form
        style={{ backgroundColor: 'whitesmoke', borderRadius: '5%'  }}  
         className='m-1 p-2 mb-4' 
            onSubmit={handleUpdate}
        >
            <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide className='w-100'
        
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
              <Toast.Header className='  bg-light text-dark '  >
                  <small className='me-auto'>Message</small>
              </Toast.Header>

              <Toast.Body className= 'text-center'
                  style={style}>
                    <p><b> {message} </b></p>
              </Toast.Body>
        </Toast>
            {
                // Option for Updating either BVN or NIN
                bvn.length === 0 && nin.length === 0 &&
                <FormGroup className="mb-3">
                <Form.Label> Choose which you prefer to add, BVN or NIN.  </Form.Label>
                <Form.Select
                type='text'
                value={bvn}
                onChange={(e) => setUpdate(e.target.value)}
                disabled = {!edit}
                
                >
                    <option> Select Option... </option>
                    <option value={'bvn'}>  BVN </option>
                    <option value={'nin'}>  NIN </option>
                    <option value={'both'}>  BVN & NIN </option>
                </Form.Select>
            </FormGroup>
            }
                 
                {
                    // BVN Text box for 
                    update == 'bvn' && 
                    <FormGroup className="mb-3">
                        <Form.Label> Enter BVN </Form.Label>
                        <Form.Control
                        type='number'
                        placeholder='Enter Bank Verification Number.'
                        value={bvn}
                        onChange={(e) => setbvn(e.target.value)}
                        disabled = {!edit} 
                        
                        >
    
                        </Form.Control>
                    </FormGroup>
                }
                {
                    // NIN Text box
                    update == 'nin' && 
                   
                   <FormGroup className="mb-3">
                       <Form.Label> Enter NIN </Form.Label>
                       <Form.Control
                       type='number'
                       placeholder='Enter your National Identity Number.'
                       value={nin}
                       onChange={(e) => setnin(e.target.value)}
                       disabled = {!edit}
                       
                       >
   
                       </Form.Control>
                   </FormGroup>
                }

                {
                    // Two text box for both BVN and NIN
                    update == 'both' && 
                    <>
                        <FormGroup className="mb-3">
                            <Form.Label> Enter BVN </Form.Label>
                            <Form.Control
                            type='number'
                            placeholder='Enter Bank Verification Number.'
                            value={bvn}
                            onChange={(e) => setbvn(e.target.value)}
                            disabled = {!edit} 
                            
                            >

                            </Form.Control>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Form.Label> Enter NIN </Form.Label>
                            <Form.Control
                            type='number'
                            placeholder='Enter your National Identity Number.'
                            value={nin}
                            onChange={(e) => setnin(e.target.value)}
                            disabled = {!edit}
                            
                            >

                            </Form.Control>
                        </FormGroup>
                    </>
                }
            {
                showBVNandNIN &&
                // BVN and NIN update
                <>
                    <FormGroup className="mb-3">
                        <Form.Label> Enter BVN </Form.Label>
                        <Form.Control
                        type='number'

                        placeholder='Enter Bank Verification Number.'
                        value={bvn}
                        onChange={(e) => setbvn(e.target.value)}
                        disabled = {showBVNandNIN ? (bvnOriginal.length > 0 && true) : !edit} 
                        
                        >

                        </Form.Control>
                    </FormGroup>
            
                     {/* update == 'nin' && */}
                    
                    <FormGroup className="mb-3">
                        <Form.Label> Enter NIN </Form.Label>
                        <Form.Control
                        type='number'
                        placeholder='Enter your National Identity Number.'
                        value={nin}
                        onChange={(e) => setnin(e.target.value)}
                        disabled = {showBVNandNIN ? (ninOriginal.length > 0 && true) : !edit}
                        
                        >

                        </Form.Control>
                    </FormGroup>

                </>
                }
            <FormGroup className="mb-3">
                <Form.Label> Name </Form.Label>
                <Form.Control
                type='text'
                value={name}
                onChange={(e) => setname(e.target.value)}
                disabled={!edit}
                
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> Email </Form.Label>
                <Form.Control
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled ={true}
                
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> phone </Form.Label>
                <Form.Control
                type='text'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                disabled ={!edit}
                
                >

                </Form.Control>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label> Username </Form.Label>
                <Form.Control
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled = {true}
                
                >

                </Form.Control>
            </FormGroup>
            
            <FormGroup>
                <Button className='w-100' variant='dark'
                 type={buttonType} 
                 disabled={buttonDisabled}
                 onClick={() => {
                    setEdit(!edit);
                    setTimeout(() =>{setButtonName('Submit')}, 1000)
                    setButtonType('submit')
                }}>
                {buttonDisabled ? 
                    ( <center>
                        <Spinner className='d-flex'
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        />
                        </center>
                    ) :  
                    buttonName
                }    
                </Button> 
            </FormGroup>
        </Form>
    </Col>
    </Row>
  )
}
