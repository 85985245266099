import React, {useState, useContext, useEffect} from 'react'
import { walletHistory, userData } from './API';
import DataContext from './DataContext';
import Head from './Head';
import MyTable from './Table';
import { Spinner, Container, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';


export const WalletHistory = () => {
    const {getUser, deleteUser, width} = useContext(DataContext);
    const [history, setHistory] = useState();
    const navigate = useNavigate();
    const user = getUser('user');
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('black')
    const style = { backgroundColor: 'white', borderRadius: '40px', color };


    useEffect(() => {
        document.title = "UXTECH | Wallet History"
        if(!user){
            navigate('/login?login');
        
        }else{
            const getUserData = async () => {
                const uData = await userData()
                if(!uData.success){
                    setMessage("User Session has Expired. Please Login again!")
                    setColor('red');
                    setShow(true);
                   setTimeout(()=> {
                    deleteUser('user');
                    navigate('/login?login')
                   }, 3000);
                }
                
              }
      
                getUserData()
        }
        }, [])

    const getHistory = async () => {
        const email = getUser('user').data.email;
        // console.log(email)
        const data = {
            email
        }
        const response = await walletHistory(data);
        setHistory(response.data);
        // console.log(response);
    }

    const columns = [
        
        {
        accessor: 'type',
        Header: 'Transaction Type',
        },
        {
        accessor: 'amountSent',
        Header: 'Amount Funded',
        },
        {
            accessor: 'oldBalance',
            Header: 'oldBalance',
            },
            {
                accessor: 'newBalance',
                Header: 'newBalance',
                },
                {
                    accessor: 'charge',
                    Header: 'fee charged',
                    },
         {
        accessor: 'userAccountName',
        Header: 'From',
        },
        {
            accessor: 'date',
            Header: 'Date',
            },
    
      ]

    useEffect(() => {
        console.log(history);
        getHistory()
    }, [])

    const data = useMemo(() => history, [history]);
  return (
    !history? ( 
        <div >
        <center> <h2>
              
              <Spinner className="d-flex mt-5"
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              />
        Loading... </h2>
        <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide className={width < 600? 'w-100': 'w-75' }
        
          style={{ borderRadius: '40px', backgroundColor: 'white' }}
          >
            <Toast.Header className='  bg-light text-dark '  >
                <small className='me-auto'>Message</small>
            </Toast.Header>

            <Toast.Body className= 'text-center'
                style={style}>
                  <p><b> {message} </b></p>
          </Toast.Body>
       </Toast>
       </center>
      </div> 
    ) :  (
<div className='home'>
<Head/>
<div style={{ marginTop: '10px' }} >
<Container>
<h2 className="text-center"> Wallet Funding History </h2>

    <MyTable body={history} columns={columns} path="wallet" />
    </Container>
</div>
</div>

  )

  )
}
