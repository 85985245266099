import React from 'react';
import { useState, useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Head from './Head';
import MyButton from './Button';

const ExamCard = () => {
const [price, setPrice] = useState('0');
const [tPrice, setTPrice] = useState('0');

useEffect(() => {
    document.title = "UXTECH | Buy Exam Pin"
}, [])
  return (
    <div className='mx-auto home'>
        <Head />
        
       

    <center>
    <h2 className='mt-2'> BUY EXAMINATION CARD </h2>
    <Form 
        className='col-lg-4 col-md-6 col-sm-8 col-xs-10 text-start mt-4'
        style={{ margin: '10%', marginTop: '5px', backgroundColor: 'whitesmoke'  }}
     >
        
        <Form.Label> Select Exam Type </Form.Label>
        <FormGroup>
            <Form.Select>
            <option>
                    Select Exam...
                </option>
                <option disabled={true}>
                    WAEC
                </option>
                <option disabled={true}>
                    NECO
                </option>
                <option disabled={true}>
                    NABTEB
                </option>
            </Form.Select>
        </FormGroup>
        <Form.Label> Quantity </Form.Label>
        <FormGroup>
            <Form.Select>
                <option>
                    Choose
                </option>
                <option>
                    1
                </option>
                <option>
                    2
                </option>
                <option>
                    3
                </option>
            </Form.Select>
        </FormGroup>
        <Form.Label> Price </Form.Label>
        <FormGroup>
            <Form.Control type='text' value={price} readOnly/>
        </FormGroup>
        <Form.Label> Total Amount </Form.Label>
        <FormGroup>
            <Form.Control type='text' value={tPrice} readOnly/>
        </FormGroup>
        <MyButton name={'Continue'} active={false} disable={true}/>
    </Form>
    </center>
    </div>
  )
}

export default ExamCard