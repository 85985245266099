import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

const MyButton = ({active, name, disable}) => {
  return (
    <div>
      <Button size='lg' variant="dark" disabled={disable || active}  type="submit" 
      className="btn-block-level w-100" 
      style={{ marginTop: '20px'}}
      >
                    
                    {active && 
                    <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />}
                    { !active ? <small><i> {name} </i> </small>: <b>Loading...</b> }
                </Button>
    </div>
  )
}

export default MyButton
