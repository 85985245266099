import './style.css';
const About = () =>{

    return (
        <div className="body"> 
            <h1> About Page</h1>

        </div>
    )
} 

export default About