import { FormGroup, Form, Button, ButtonGroup , Row, Col, Container, Spinner} from "react-bootstrap";
import Cookie  from "react-cookies";
import { useState, useContext } from "react";
import {useParams, useLocation, Link, useNavigate, useSearchParams, } from 'react-router-dom'; 
import {login} from './API';
import Alert from './Alert';
import DataContext from "./DataContext";
import MyButton from "./Button";
import { useEffect } from "react";
import  {Login} from "./Signin";
import { Register } from "./Signup";


const MyForm = () =>{
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    
    const [active, setActive] = useState(false)
    const { setUser, setActiveUser} = useContext(DataContext)
    const [input, setInput] = useState({});

    const state = useLocation().search.substr(1);
    const [request, setRequest] = useState(state === 'login'?'login': 'register')
    const [status, setStatus] = useState(state === 'login'? true : false)
   
    // console.log(state)
    // setStatus(state);
    // console.log(useLocation('search'))
    // console.log(state)

    // let query = useQuery()
    //  const name = useSearchParams()[0]
    //  console.log(name)
    // console.log(useParams())
    // console.log(useLocation())
    // console.log(useSearchParams())
return (
    
    <div className="pt-1">
        
        <center >
            <img 
            // className="card"
            src={require("./image/ux.png")}
            alt="UX-ELITE TECHNOLGIES"
            width='200'
            style={{ 
                margin: 'auto', 
                marginBottom: '20px',
                height: '50px' 
            }}
            />
        </center>
        <div className="row"  >
        
            <div className="col-12"  style={{ height: '50px', borderRadius: '50px' }}>
                <div className="row justify-content-center px-4" >
                    <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 px-0"
                    style={{ 
                        border: 0,
                        borderTop: 1,
                        borderBottom: 1,
                        borderStyle: 'groove',
                        borderColor: 'balck',
                        borderRadius: '50px'
                     }}
                    >
                        <Button 
                        className="w-50" 
                        style={{ borderRadius: '50px', borderColor: 'white' }} 
                        size='lg'
                        onClick={() => {
                            setRequest('login')
                            setStatus(true)
                            
                            }} variant='outline-dark' active={status}>
                                Login 
                            </Button>

                        <Button 
                        className="w-50"
                        style={{ borderRadius: '50px', borderColor: 'white' }} 
                        size="lg" 
                        onClick={() => {
                        setRequest('Register')
                        setStatus(false)
                        
                        }} variant='outline-dark' active={!status}>Register
                        </Button>  
                    </div>
                </div>
            </div>  
            <div className="col-12">
                { request === 'login'? <Login/> : <Register/>  }
            </div>
        </div>
    
    </div>
)

}

export default MyForm;