import React, { useState } from 'react';
import {Button, Table, Form, FormGroup} from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Receipt } from './Reciept';





const MyTable = ({ body, columns, path}) => {

    const [receiptData, setReceiptData] = useState(null);
const [show, setShow] = useState(false);
const handleReceipt = (data) => {
    setReceiptData(data);
    setShow(true)

}

const {getTableProps, getTableBodyProps, headerGroups, page, 
    previousPage, nextPage, canNextPage, canPreviousPage, 
    pageOptions,
    prepareRow,
    state, 
    setGlobalFilter

} = useTable({
    columns,
    data:body
},
 useGlobalFilter, usePagination,
);

const {pageIndex, globalFilter} = state

  return (
    <div>
        
        <Receipt
      show={show}
      data={receiptData}
      onHide={() => setShow(false)}
    />
    
        <FormGroup className='d-flex justify-content-end'>
        <Form.Control
        size='xl'
        width='35%'
        style={{ width: '45%' }}
        value={globalFilter}
        placeHolder="search"
        onChange={e => setGlobalFilter(e.target.value)}
        className='m-2 flex-row-reverse '
        />
        </FormGroup>
      <Table  hover size="lg" responsive {...getTableProps()} style={{ backgroundColor: 'whitesmoke', border: '20px' }}>
                    <thead>
                         {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map( (column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))
                            }
                        </tr>
                    ))}
                        {/* <tr>
                        <th>Ref</th>
                        <th>Type</th>
                        <th>Price-Size</th>
                        <th>To</th>
                        <th> {JSON.parse(history.status)}</th>
                        
                        <th> Action </th>
                        </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr 
                                onClick={() =>{
                                    
                                    if(path !== 'wallet'){
                                        handleReceipt(row.original)
                                    }
                                    
                                
                                }}
                                {...row.getRowProps()}>
                                  {
                                    
                                    row.cells.map((cell, index) =>{
                                        
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {/* {console.log(cell)} */}
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })
                                  }
                                </tr>
                            )
                        })}
                            
                            
                        
                    </tbody>
                </Table>
                <div style={{ marginBottom: '40px' }}>
                <center> 
                <Button disabled={!canPreviousPage}onClick={() => previousPage()} variant='dark' style={{ width: '100px' }} className=''> Previous </Button>
                <span>
                        Page {pageIndex.length}
                       <strong> {pageIndex + 1} of {pageOptions.length} </strong>
                    </span>
                <Button disabled={!canNextPage} onClick={() => nextPage()} variant='dark' style={{ width: '100px' }} className=''> Next </Button>
                </center>
                </div>
    </div>
  )
}

export default MyTable
