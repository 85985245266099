import React from 'react'


    import {useState, useEffect} from 'react';
    import {useNavigate} from 'react-router-dom';
    import { FormGroup, Form, Button, Toast, Spinner } from 'react-bootstrap';
    import DataContext from './DataContext';
    import { useContext } from 'react';
    import Head from './Head';
    import { buyDataCard, getDataCardPlans, getTransactions, getNetworks, getNetworkInitials as fetchNetworkInitials } from './API';
    import MyButton from './Button';
    import Alert from './Alert';
    import { Receipt } from './Reciept';
    import './style.css'
    import axios from 'axios';
    import Swal from 'sweetalert2';
    
    
    
    
    const BuyDataCard = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const {getUser, deleteUser} = useContext(DataContext); 
    
    // const [price, setPrice] =useState(response());
    // console.log(price);
    const user = getUser('user');
    const [type, setType] = useState(null);
    const [email, setEmail] = useState(null);
    const [networkProviders, setNetworkProviders] = useState(null);
    const [prices, setPrices] = useState(null);
    const [plan, setPlan] = useState(null)
    const [amount, setAmount] = useState(null);
    const getPlanTypes = async () =>{
        const response = await getDataCardPlans();
        setPrices(response);
        // setPlan(response.data[0].prices);
        // setAmount(response.data[0].prices[0].price);
        // console.log(response)
    }
    const getNetworkProviders = async () =>{
        const response = await getNetworks();
        // console.log(response);
        setNetworkProviders(response);
        setPrices(response);
    }
    
    const [ntwrk, setNtwrk] = useState(null)
    const [network, setNetwork] = useState(null)
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('');
    const [active, setActive] = useState(false);
    const [show, setShow] = useState(false);
    const [receiptData, setReceiptData] = useState(null);
    const [showReceipt, setShowReceipt] = useState(false);
    const [color, setcolor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };
    const [networkId, setNetworkId] = useState(0);
    const [myArray, setMyArray] = useState(null);
    const testArray = [];
    const [data, setData] = useState({
         network_id: network && network.networkID,
        plan: plan && plan[1],
        quantity:null,
        card_name: 'uxtech',
        platform: 'web'
    })
    // const [buttonActive, setButtonActive] = useState(true);
    // const [countDown, setCountDown] = useState(60);
    // var timer = 0;
    // setInterval(() => {
        
    //     if(countDown !== 0){
    //         timer = countDown - 1
    //     }
    //     setCountDown( timer);
    //     // console.log(timer)
    // }, 1000);
   
    const transactionHistory = async () => { 
         
        const data = user.data.email
        const history = await getTransactions((data));
       setReceiptData(history.data[0]);
    }
    
    useEffect(() => {
        document.title = 'UXTECH | Buy Data Card';
        if(!user){
            navigate('/login?login');
        
        }else {
            getNetworkProviders(); 
            getPlanTypes();
            // transactionHistory()
            // setShowReceipt(true)
            // console.log(receiptData)
            setType(user.data.type);
            setEmail(user.data.email);
    
            setStatus(true)
        }
        }, [])
    
    const handleSelect = (e) => {
        const value = JSON.parse(e.target.value)
    //    setNetwork(value.network);
       setNtwrk(value.network);
       setData({...data, network_id: value.networkID})
       setPlan('');
       setAmount('')
       setTimeout(() =>{
        setPlan(value.plans)
       }, 1000)
        
        // setAmount(value.prices[0].price)
    }
    const handleAmount = (e) => {
        const value = JSON.parse(e.target.value);
        setAmount(value[0]);
        setData({...data, plan: value[1]})
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setActive(true)
    if(amount === null || amount === ''){
        Swal.fire({
            text: 'Please select a plan!',
            icon: 'warning',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        // setMessage("Please select a plan!");
        // setcolor('red');
        // setShow(true);
        setActive(false);
    }else if(data.quantity === null || amount === ''){
        Swal.fire({
            text: 'Please enter quantity to purchase!',
            icon: 'warning',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        // setMessage("Please enter quantity to purchase!");
        // setcolor('red');
        // setShow(true);
        setActive(false);
    } else{
           
        // const buyNow = async () => {
            try {
                const response = await buyDataCard(data);
                // console.log(response);
                if(response.success){
                    Swal.fire({
                        text: response.message,
                        icon: 'success',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    // setcolor('black');
                    // setMessage(response.message)
                    // setShow(true)
                    transactionHistory()
                   
                    setTimeout(() => {
                        setShowReceipt(true)
                    }, 1000);
                    e.targe.reset();
                    setActive(false);
    
                    // console.log(response.message)
                }else if(response.status == 500){
                    Swal.fire({
                        text: 'User Session has Expired. Please Login again!',
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    // setMessage("User Session has Expired. Please Login again!")
                    // setcolor('red');
                    // setShow(true);
                   setTimeout(()=> {
                    deleteUser('user');
                    navigate('/login?login')
                   }, 2000);
                    
                }else{
                    Swal.fire({
                        text: response.message,
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    // console.log(response);
                    // setMessage(response.message)
                    // setcolor('red');
                    // setShow(true)
                    setTimeout(() => setActive(false), 11000);
                    // console.log(response.message)
                }
               
    
            } catch (error) {
                
            }
            // finally{
                
            //     setActive(false)
            //     // console.log(active)
            // }
        }
    
        // buyNow()
        // // console.log(data) 
        // }
       
    }
   
        return  !status ? ( <center> <h2>
          
            <Spinner className='d-flex mt-5'
            as="span"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
            />
            Loading... </h2></center>)
            : (
    
        <div> 
                <Head/>
        
            <div className='home row m-lg-5 pt-lg-4  m-3 pt-5'>
            
            
           <div style={{ backgroundColor: "whitesmoke", }}
           className='col-lg-8 col-md-8 col-sm-12 col-xs-12 ps-lg-5 pe-lg-3 ps-xs-3 '
           >
            <h2 className='text-center'> Buy Data Card/Pin </h2>
            <center>
            <Form onSubmit={handleSubmit} 
            method='post'
            className='col-lg-6 col-md-8 col-sm-10 col-xs-12' 
            style={{ margin: '2%'  }} >
            <Receipt
                show={showReceipt}
                data={receiptData}
                type={"purchase"}
                onHide={() => {
                    setShowReceipt(false);
                    navigate('/dashboard')
                }}
                onClose={() => {
                    setShowReceipt(false)
                    setPhone('')
                    setActive(false);
                }}
                    
                />
            <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
            
            style={{ borderRadius: '40px', backgroundColor:'white' }}
            >
              <Toast.Header className='  bg-light text-dark '  >
                  <small className='me-auto'>Message</small>
              </Toast.Header>
              <Toast.Body className= 'text-center'
                style={style}>
                     <p><b> {message} </b></p> </Toast.Body>
            </Toast>
                <FormGroup>
                    <Form.Label className='float-start'> Network</Form.Label>
                    <Form.Select
                    onChange={(e) => {
                        setNetworkId(JSON.parse(e.target.value).id);
                        setNetwork(JSON.parse(e.target.value).network);
                        // console.log(JSON.parse(e.target.value).id);
                        setAmount('');
                        setPlan('');
                        var i = 0;
                        prices && prices.map((network, index) => {
                            if(networkId === network.networkID){
                                setMyArray({...myArray, [index]: network})
                                testArray  [i] = network;
                                i += 1;
                            // console.log(testArray);
                            }
                            
                        })
                    }}
                    >
                        <option> Select Network </option>
                        {
                            networkProviders && networkProviders.map((network, index) => (
                                <option value={JSON.stringify(network)} key={index}> {network.network} </option>
                            ))
                        }
                    </Form.Select>
                </FormGroup>
                <FormGroup>
                        <Form.Label className='float-start'> Data Type</Form.Label>
                        <FormGroup>
                        <Form.Select onChange={handleSelect} name='network'
                         >
                        <option>
                            select data type
                        </option>
                        {networkId !== 0 && prices && prices.map((networks, index) =>  {
                                var status = networks.status 
                                
                            return networks.networkID == networkId && (
                                    <option value={JSON.stringify(networks)} key={index}
                                    disabled={status == 'false' ? false : true}
                                    >
                                        { networks.network}
                                    </option>
                               
                               )   
                        }
                            )}
                         </Form.Select>
                        </FormGroup>
                       
                </FormGroup>
                <FormGroup>
                        <Form.Label className='float-start'> Plan </Form.Label>
                        <Form.Select size='md' onChange={handleAmount}  name='plan'>
                            <option> select plan </option>
                                   { plan && plan.map((plan, index) =>(
                                    <option 
                                    value={JSON.stringify([plan.price, plan.planCode, plan.data_id, plan.size ])}
                                    key = {index}
                                    
                                    >
                                        {plan.size} for {plan.price}  {plan.duration}
                                    </option>
                                   )) }
                        </Form.Select>
                </FormGroup>
                <FormGroup>
                        <Form.Label className='float-start'> Amount </Form.Label>
                        <Form.Control type='text' value={amount} readOnly/>
                </FormGroup>
                <small className='text-muted text-start'> Amount you will be charged:  
                { amount &&
                            type === '2' &&  ntwrk !== 'MTN GIFTING' && ntwrk !== 'MTN CGIFTING'?
                        amount.split("")[0].concat(amount.substr(1) - 5)
                        : amount && type === '0' ? 
                        amount.split("")[0].concat(Number(amount.substring(1)) + 25)
                        : amount
                        }
                </small>
                {/* <FormGroup>
                        <Form.Label className='float-start'> Amount You Will Be Charge</Form.Label>
                        <Form.Control type='text' readOnly value={ amount &&
                            type === '2' &&  ntwrk !== 'MTN GIFTING' && ntwrk !== 'MTN CGIFTING'?
                        amount.split("")[0].concat(amount.substr(1) - 5)
                        : amount && type === '0' ? 
                        amount.split("")[0].concat(Number(amount.substring(1)) + 25)
                        : amount
                        }
                        />
                </FormGroup> */}
                 <FormGroup>
                        <Form.Label className='float-start'> Quantity </Form.Label>
                        <Form.Control type='number'
                        placeholder='Enter number of quantity to purchase'
                        onChange={(e) => {
                            setData({...data, quantity: e.target.value});
                        }}
                        />
                </FormGroup>
                <FormGroup>
                        {/* <Form.Label className='float-start'> Card Name(Not required) </Form.Label> */}
                        <Form.Control type='text'
                        hidden
                        placeholder='This is optional'
                        onChange={(e) => {
                            // setData({...data, card_name: e.target.value});
                            setData({...data, card_name: 'uxtech'});
                            // console.log(data);
                        }}
                        />
                </FormGroup>
                <MyButton name={"Continue"} active={active} disable={false}/>
                
            </Form>
            </center>
            </div>
            <div className='col-lg-4 col-md-4 p-4 text-center'>
                <h2 className='text-center '> Data Codes </h2>
                {/* <h6 className='text-danger'> <b> Please Note: 
                    <i>
                        Trying to load wrong pin more than 1 times will get you banned from loading data pin.
                    </i>
                    </b>
                </h6> */}
                <div className=' text-center text-dark'
                style={{ backgroundColor: 'lightyellow' }}
                >
                    <h5> MTN Codes for loading Data </h5>
                    <p> MTN CG:  *460*6*1# </p>
                    <p> MTN SME: *347*383*4*3# </p>
                    {/* <p> MTN SME: *347*666*pin# </p> */}
                    

                    <h5> MTN Data Balance</h5>
                    <p> MTN SME:  *461*4# </p>
                    <p> MTN CGIFTING:  *323*4# </p>
                </div>
                <div className=' text-center text-white'
                style={{ backgroundColor: 'red' }}
                >
                    <h5> AIRTEL Code for loading data </h5>
                    <p>*347*666*pin#</p>
                    <h5>AIRTEL Code for data balance</h5>
                    <p> Airtel Cooperate Gifting:  *140#  </p>
                </div>
                <div className=' text-center text-white'
                style={{ backgroundColor: 'darkgreen' }}
                >
                    <h5> GLO Code for loading data </h5>
                    <p>*347*383*4*3*PIN# or *347*383*4*PIN*PHONE#</p>
                    <h5>Glo code for data balance</h5>
                    <p> GLO Cooperate Gifting:  *127*0#. </p>
                </div>
                {/* <div className=' text-center text-dark'
                style={{ backgroundColor: 'lightgreen' }}
                >
                    <h5> 9MOBILE </h5>
                    <p> 9MOBILE GIFTING:  *228#  </p>
                </div> */}
            </div>
            </div>
        </div>
        )
    }

export default BuyDataCard
