import React, {useState, useEffect, useContext} from 'react'
import { aidaElectricity, meter } from './plans';
import DataContext from './DataContext';
import { Form, FormGroup, formGroup, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MyButton from './Button';
import Head from './Head';
import axios from 'axios';
import { buyElectricityApi, discoPlans, validateMeter, getTransactions } from './API';
import Swal from 'sweetalert2';
import { Receipt } from './Reciept';

const BuyElectricity = () => {
    const {getUser, deleteUser} = useContext(DataContext);
    const user = getUser('user');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [customer, setCustomer] = useState({
        name: "",
        address: ""
    });
    const [data, setData] = useState({
        disco_name:null,
        meter_type: null,
        meter_number: null,
        meter_name: null,
        meter_address: null,
        phone: null,
        amount: null
    });
    const [buttonName, setButtonName] = useState('Validate Meter');
    const [active, setActive] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('black');
    const style = {backgroundColor: 'white', borderRadius: '40px', color};
    const [receiptData, setReceiptData] = useState(null);
    const [showReceipt, setShowReceipt] = useState(false);
    const [discos, setDiscos] = useState(null);

    const getDiscoPlans = async () => {
        const response = await discoPlans();
        setDiscos(response);
    }
    const handleElectricity = (e)=> {
        const name = e.target.name;
        const value = e.target.value;

        setData({
            ...data, [name]: value
        });

    }

    const transactionHistory = async () => { 
     
        const data = user.data.email
        const history = await getTransactions((data));
       setReceiptData(history.data[0]);
    }

    const handleSubmit = async (e)=> {
        setActive(true);
        e.preventDefault();
        const response = await validateMeter(data);

        // console.log(response);
        if(!response.invalid){
            setCustomer({...customer, name :response.name ? response.name : response.message, address:response.address});
            setData({...data, meter_name:  response.name ? response.name : response.message, meter_address: response.address});
            setShow(true);
                setButtonName('Submit Order')
                setActive(false);
        }else{
            Swal.fire({
                text: 'Invalid meter record!. Please check and try again',
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setColor('red');
            // setMessage('Invalid meter record!. Please check and try again.');
            // setShowMessage(true);
            setActive(false);
        }
    }

    const handleProcessOrder = async (e) => {
        setActive(true);
        e.preventDefault();
        if(data.amount === null || data.phone === null ){
            Swal.fire({
                text: 'Please fill all the fields',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setColor('red');
            // setMessage("Please fill all the fields");
            // setShowMessage(true);
            setActive(false);
        }else if(data.amount < 1000 ){
            Swal.fire({
                text: 'Minimum amount is 1000 naira.',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setColor('red');
            // setMessage("Please fill all the fields");
            // setShowMessage(true);
            setActive(false);
        }else{
            const response = await buyElectricityApi(data);
            // console.log(response);
            if(response.success){
                Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                transactionHistory();
                setShowReceipt(true);
                // setColor('black');
                // setMessage(response.message);
                // setShowMessage(true);
                setActive(false);
            }else if(response.status === 401){
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setColor('red');
                // setMessage(response.message);
                // setShowMessage(true);
                setActive(false);
                setTimeout(()=> {
                    deleteUser('user');
                    navigate('/login?login')
                }, 3000);
            }else{
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setColor('red');
                // setMessage(response.message);
                // setShowMessage(true);
                setActive(false);
            }
        }
        
    }

    useEffect(() => {
        document.title = "UXTECH | Buy Electricity"
        getDiscoPlans();
    }, []);
    // useEffect(()=>{
    //     console.log(data);
    // }, [data])
    
  return (
    <div className='mx-auto home'>
        <Head />
        
      <Form className='col-lg-4 col-md-6 col-sm-8 col-xs-8 mx-auto'
      onSubmit={buttonName === 'Validate Meter' ? handleSubmit : handleProcessOrder}
      >
        <Receipt
            show={showReceipt}
            data={receiptData}
            type={"purchase"}
            onHide={() => {
                setShowReceipt(false);
                navigate('/dashboard')
                setActive(false)
            }}
            onClose={() => {
                setShowReceipt(false)
                // setPhone('')
                setActive(false)
            }}
                
            />
        <Toast onClose={() => setShowMessage(false)} show={showMessage} delay={4000} autohide className='w-100'
        
        style={{ borderRadius: '40px', backgroundColor:'white' }}
        >
          <Toast.Header className='  bg-light text-dark '  >
              <small className='me-auto'>Message</small>
          </Toast.Header>
          <Toast.Body className= 'text-center'
            style={style}>
                 <p><b> {message} </b></p> </Toast.Body>
        </Toast>
        
      <h2 className='text-center'> buy Electricity </h2>
        <FormGroup>
            <Form.Label> Disco Name </Form.Label>
            <Form.Select
            name='disco_name'
            required
            onChange={handleElectricity}
            >
            <option>---- </option>
                { discos && discos.map((plan) => (
                
                <option value={plan.discoName}> {plan.discoName}</option>
                ))}
            </Form.Select>
        </FormGroup>
        <FormGroup>
            <Form.Label> Meter Number </Form.Label>
            <Form.Control type='text' placeholder='Enter Meter Number'
            name='meter_number'
            required
            onChange={handleElectricity}
            >

            </Form.Control>
        </FormGroup>
        
        <FormGroup>
            <Form.Label> Meter Type </Form.Label>
            <Form.Select
            onChange={handleElectricity}
            name='meter_type'
            required
            >
                <option>
                    ---
                </option>
               {meter.map((meter) => (
                <option value={meter.meterName}>
                    {meter.meterName}
                </option>
               ))}
            </Form.Select>
        </FormGroup>

        <FormGroup>
            <Form.Label> Amount </Form.Label>
            <Form.Control type='text' placeholder='Enter Amount to purchase'
            name="amount"
            required
            onChange={handleElectricity}
            ></Form.Control>
        </FormGroup>

        <FormGroup>
            <Form.Label> Customer Phone </Form.Label>
            <Form.Control
            name="phone"
            required
            onChange={handleElectricity}
            type='text' 
            placeholder='Enter Customer Phone Number'
            />
        </FormGroup>
        { show &&
        <FormGroup>
            <h6 className='text-center'> Meter Details </h6 >
            <Form.Label> Meter Name </Form.Label>
            <Form.Control type='text' value={customer.name} ></Form.Control>
            {/* <Form.Label> Meter Address </Form.Label>
            <Form.Control type='text' value={customer.address} ></Form.Control> */}
        </FormGroup>
        
        }
        {/* <FormGroup>
            <Form.Label> Charge </Form.Label>
            <Form.Control type='text' value='50.0 naira service charge' readOnly></Form.Control>
        </FormGroup> */}
        <small className='text-muted'
        style={{ 
            fontSize: 12
         }}
        > Charge: 1% </small>
        <MyButton name={buttonName} active={active}/>
      </Form>

    </div>
  )
}

export default BuyElectricity

// {
//     "status": "success",
//     "name": "PROF AKANYA FUMILAYO  "
// }

