import { Button, Pagination, Container, Spinner, Toast } from "react-bootstrap";
import {useState, useEffect, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookie from 'react-cookies';
import {getTransactions, userData} from './API';
import DataContext from "./DataContext";
import { useTable } from "react-table";
import MyTable from "./Table";
import Head from "./Head";
import './style.css'
import { Link } from "react-router-dom";


const Transactions = () => {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const {getUser, deleteUser, width} = useContext(DataContext);
    const user = getUser('user');
    // console.log(user.data[0].email)
    const [transHistory, setTransHistory] = useState([]);
    const [lastTransaction, setLastTransaction] = useState();
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('black')
    const style = { backgroundColor: 'white', borderRadius: '40px', color };


    const transactionHistory = async () => { 
     
         const data = user.data.email
         const history = await getTransactions();
        setTransHistory(history);
        // console.log("history is here", history)
        
       
    }

    useEffect(() => {
      document.title = "UXTECH | Transaction History";
      if(!user){
          navigate('/login?login');
      
      }else{
        const getUserData = async () => {
          const uData = await userData()
          if(!uData.success){
              setMessage("User Session has Expired. Please Login again!")
              setColor('red');
              setShow(true);
             setTimeout(()=> {
              deleteUser('user');
              navigate('/login?login')
             }, 3000);
          }
          
        }

          getUserData()
      }
      }, [])

    const userTransactions = transHistory.data
    // console.log(userTransactions)
   
    // console.log(lastTransaction);
    
    // const d = transactions.data[0];
    // console.log(transactions);
    useEffect(() => {
        transactionHistory()
        
        
    }, [])
    
    // console.log(d.id)
    const trans  = localStorage.getItem('trans');
   setTimeout(() => {
    // console.log(trans)
   }, 4000)
  const userdata = JSON.parse(trans);
  const test = [
   { data: [{
        name: 'usman',
        username: 'uselite'
    }]}
  ]

  
  const columns = [
    // {
    // accessor: `transaction_id`,
    // Header: 'Ref',
    // },
    {
      accessor: `date`,
      Header: 'Date/Time',
      },
    {
    accessor: 'type',
    Header: 'Type',
    },
     {
    accessor: 'price',
    Header: 'Price',
    },
    {
    accessor: 'phone',
    Header: 'To',
    },
    {
      accessor: 'status',
      Header: 'Status',
      },
    {
    Header: "Receipt",
    accessor: (row) => <Button variant="dark" className="w-100"> Reciept</Button>,
    }

  ]
  
    const data = useMemo(() => userTransactions, [userTransactions]);
    // const columns = useMemo(() => column, []);
    
    return !userTransactions? ( 
      <div >
        <center> <h2>
              
              <Spinner className="d-flex mt-5"
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              />
        Loading... </h2>
        <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide className={width < 600? 'w-100': 'w-75' }
        
          style={{ borderRadius: '40px', backgroundColor: 'white' }}
          >
            <Toast.Header className='  bg-light text-dark '  >
                <small className='me-auto'>Message</small>
            </Toast.Header>

            <Toast.Body className= 'text-center'
                style={style}>
                  <p><b> {message} </b></p>
          </Toast.Body>
       </Toast>
       </center>
      </div> 
       ) :  (
        <div className='home'>
        <Head/>
        <div style={{ marginTop: '10px' }} >
          <Container>
            <h2 className="text-center"> Transactions </h2>
            
    {/* import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const columns = [{
  dataField: 'id',
  text: 'Product ID'
}, {
  dataField: 'name',
  text: 'Product Name'
}, {
  dataField: 'price',
  text: 'Product Price'
}];

<BootstrapTable keyField='id' data={ products } columns={ columns } pagination={ paginationFactory() } /> */}
            {/* {transactions} */}

                <MyTable body={data} columns={columns} path = "transaction" />
                </Container>
        </div>
        </div>
    ) 
}

export default Transactions;