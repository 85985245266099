import './style.css';
// import  { Redirect } from 'react-router-dom'
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Cookie from 'react-cookies';
const user = Cookie.load('user');
const Contact = () =>{
    let user = 1;
    let navigate = useNavigate();
    useEffect(() => {
        if(user !== 1)
        navigate('/')
       }, [])
    //   let data = Cookie.load('user')
    //    console.log(Cookie.load('user'))
        return (
            <div className="home"> 
               
                <h1> Contact Page </h1>
            </div>
        )
  
   
} 

export default Contact