import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Head from './Head';
import MyButton from './Button';
import { getCables, verifyIUC, buyCable } from './API';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Cables = () => {
    const navigate = useNavigate();
const [price, setPrice] = useState('0');
const [tPrice, setTPrice] = useState('0');
const [cables, setCables] = React.useState(null);
const [cablePlans, setCablePlans] = React.useState(null);
const [cable, setCable] = React.useState(null);
const [data, setData] = React.useState({});
const [IUCverified, setIUCVerified] = useState(false);
const [active, setActive] = useState(false);

const fetchCables = async ()=> {
    const response = await getCables();
    // console.log(response);
    setCables(response[0]);
    setCablePlans(response[1]);
}
const checkIUC  = async (e) => {
    setActive(true);
    e.preventDefault();
    const response = await verifyIUC(data);
    // console.log(response); 
    if(response.success){
        setData({...data, iuc_name: response.info });
        setIUCVerified(true);
        
    }else if(response.code == 'ERR_NETWORK'){
        Swal.fire({
          title: "No Internet Network. Please, check your network and try again.",
          placement: 'top',
          duration: 8000
        })

      }else{
        Swal.fire({
            title: response.message
          })
      }
      setActive(false);
}
const handleSubmit  = async (e) => {
    setActive(true);
    e.preventDefault()
    const response = await buyCable(data);
    console.log(response); 
    if(response.success){
        setIUCVerified(false);
        setData({
            ...data, iuc_number: '', iuc_name: ''
        })
        Swal.fire({
            text: response.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        
    }else if(response.code == 'ERR_NETWORK'){
        
        Swal.fire({
            text: "No Internet Network. Please, check your network and try again.",
            icon: 'error',
            timer: 8000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
      }else if(response.code === "ERR_BAD_REQUEST"){
        Swal.fire({
            text: "User session has expired. Login again.",
            icon: 'error',
            timer: 8000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        setTimeout(()=> {
            // deleteUser('user');
            navigate('/login?login')
           }, 4000);
      }else{
        Swal.fire({
            text: response.message,
            icon: 'error',
            timer: 8000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
      }
      setActive(false);
}  

React.useEffect(()=> {
    document.title = "UXTECH | Buy Cable";
    fetchCables();
    
}, [])
useEffect(() => {
    // console.log(data)
}, [data])
  return (
    <div className='mx-auto home'>
        <Head />
        
       

    <center>
    <h2 className='mt-2'> BUY CABLES </h2>
    <Form 
    onSubmit={IUCverified ? handleSubmit : checkIUC}
        className='col-lg-4 col-md-6 col-sm-8 col-xs-10 text-start mt-4'
        style={{ margin: '10%', marginTop: '5px', backgroundColor: 'whitesmoke'  }}
     >
        
        <Form.Label> Select Cable Name </Form.Label>
        <FormGroup>
            <Form.Select
            onChange={(e) => {
                // setCable(e.target.value)
                cablePlans &&
                    setCable(cablePlans.filter((cable) => cable.type === e.target.value));
                    setData({
                        ...data, cable_name: e.target.value
                    });
            }}
            >
            <option>
                Select Cable...
            </option>
            { cables &&
                cables.map((cable, index) => (
                    <option
                    index={index}
                    value={cable.cable}
                    disabled={false}
                    >
                        {cable.cable}

                    </option>
                ))
            }
            
        </Form.Select>
        </FormGroup>
        <Form.Label> Cable Plan </Form.Label>
        <FormGroup>
            <Form.Select
            onChange={(e) => setData({
                ...data, cable_id: e.target.value
            })}
            >
                <option>
                    Select Plan ...
                </option>
                {   cable &&
                    cable.map((plans, index) => (
                        <option disabled={false} value={plans.cable_id}>
                            {plans.plan + " "+ plans.validity + " "+ "₦"+plans.amount}
                        </option>
                    ))
                }
            </Form.Select>
        </FormGroup>
        <FormGroup>
        <Form.Label> Smart Card/IUC Number* </Form.Label>
            <Form.Control
            onChange={(e) => setData({
                ...data, iuc_number: e.target.value
            })}
            type='text'  />
        </FormGroup>
        {
            IUCverified &&
            <FormGroup>
            <Form.Label> Smart Card/IUC Name* </Form.Label>
                <Form.Control
                value={data && data.iuc_name}
                type='text'  />
            </FormGroup>
        }
        {/* <FormGroup>
        <Form.Label> Charge </Form.Label>
            <Form.Control type='text' value={'0.5%'} readOnly/>
        </FormGroup> */}
        <small className='text-bold text-muted'>Charge: 0.5% </small>
        <MyButton name={IUCverified? 'Continue': 'Validate IUC'} active={active} disable={false}/>
    </Form>
    </center>
    </div>
  )
}

export default Cables