import React, {useState, useEffect} from 'react'
import { Button, Form, FormGroup } from 'react-bootstrap'
import {createToken as getUserToken, getToken, getAPIDataPlans, getCables, discoPlans as getDiscoPlans,
    getNetworks
} from '../API';
import {electricity} from '../plans';
import { useNavigate } from 'react-router-dom';
const UserToken = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [plans, setPlans] = useState(null);
    const [cablePlans, setCablePlans] = React.useState(null);
    const [cables, setCables] = React.useState(null);
    const [discoPlans, setDiscoPlans] = useState(null);
    const [networks, setNetworks] = useState(null);

    const createToken = async () => {
        const response = await getUserToken();
        // console.log(response);
        alert(response.message);
        // console.log('Clicked');
        setToken(response.token);
    }
    const updateToken = async () => {

    }
    const setUserToken = async () => {
        const response = await getToken();
        // console.log(response);
        if(response.token.length > 0 ){
            setToken(response.token);
        }else{
        
        navigate('/login?login')
        }
        
    }
    const setDataPlans = async () => {
        const response = await getAPIDataPlans();
        setPlans(response);
        // console.log(plans);
    }
    const fetchCables = async ()=> {
        const response = await getCables();
        // console.log(response);
        setCables(response[0]);
        setCablePlans(response[1]);
        // console.log(cablePlans)
    }
    const fetchDiscoPlans = async ()=> {
        const response = await getDiscoPlans();
        // console.log(response);
        setDiscoPlans(response);
    }
    const fetchNetworks = async ()=> {
        const response = await getNetworks();
        console.log(response);
        setNetworks(response);
    }
    useEffect(() => {
        setUserToken();
        setDataPlans();
        fetchCables();
        fetchDiscoPlans();
        fetchNetworks();
    }, []);
  return (
    <div className='contsiner p-2'
    style={{ 
        backgroundColor: 'whitesmoke',
        borderRadius: '5%'
     }}
    >
        <div className='container-fluid '>
            <h1 className='text-center text-dark'> API Documentation </h1>
            <div className=''>
                <Button variant='dark'
                onClick={() => {
                    // window.location.href="https://www.postman.com/uselite/workspace/uxtech-documentaion/api/bd0630d1-b503-4164-bea2-1ab210347bec/documentation/19887568-b350e363-cb80-4393-b112-f8031d5fa209?entity=collection-b350e363-cb80-4393-b112-f8031d5fa209&version=cbecc1bb-c9f5-4894-ad5b-dfc8409d7744";
                    window.open('https://www.postman.com/uselite/workspace/uxtech-documentaion/api/bd0630d1-b503-4164-bea2-1ab210347bec/documentation/19887568-b350e363-cb80-4393-b112-f8031d5fa209?entity=collection-b350e363-cb80-4393-b112-f8031d5fa209&version=cbecc1bb-c9f5-4894-ad5b-dfc8409d7744', '_blank')
                }}
                > Go to Documentation page </Button>
            </div>
            <div
            className='container text-center col-12 '
        
            >
                <Form>
                <b className=''> User token </b>
                <div className='row justify-content-around'>
                    <div className='col-12'>
                    <FormGroup
                    className='my-2'
                    >
                        <textarea  rows={3} className='form-control' value={token} readOnly>

                        </textarea>
                        {/* <Form.Control value={token} type='text' readOnly placeholder="Click on create token to generate your token" /> */}
                    </FormGroup>
                    </div>
                    <div className='col-8 col-sm-6 col-md-5 col-lg-4 col-xxl-4' >
                    <FormGroup
                    className='my-2'
                    >
                        {
                        !token && 
                        <Button 
                        variant='dark'
                        className='w-100'
                        style={{ 
                            justifySelf: 'center'
                        }}
                        onClick={() => createToken() }
                        > Create Token
                        </Button>
                        }
                    </FormGroup>
                    </div>

                </div>
                
                </Form>
            </div>
            <div 
            className='container mt-4'
            style={{ 
                backgroundColor: 'whitesmoke'
            }}
            >
                <div
                className='container-fluid my-4 m'
                style={{ 
                    backgroundColor: 'white'
                }}
                >
                    <h4 className='text-center'> <b>NETWORKS</b>  </h4>
                    <table className='container'>
                        <thead  >
                            <th> S/N</th>
                            <th> NETWORK</th>
                            <th> NETWORK ID</th>
                        </thead>
                    <tbody>
                        {
                            networks &&
                            networks.map((network, index) => (
                                <tr>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {network.network}
                                    </td>
                                    <td>
                                        {network.id}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>

                </div>
                <div
                className='container-fluid my-4'
                style={{ 
                    backgroundColor: 'white'
                }}
                >
                    <h4 className='text-center'> <b>DATA PLANS (Price List)</b> </h4>
                    <table className='container-fluid'>
                        <thead  >
                            <th> Plan Id</th>
                            <th> Network</th>
                            <th> plan Type</th>
                            <th> Size </th>
                            <th> Price </th>
                            <th> Duration </th>
                        </thead>
                    {plans && plans.map((networks, i) => {
                        // i === 0 && console.log(networks);
                        let plans = Array.from(networks.prices);
                        // i=== 0 && console.log(plans);
                    return  plans.map((plan, index) => (
                            <tr key={index}>
                            <td>{plan.planCode}</td>
                            <td>{networks.network.split(" ")[0]}</td>
                            <td>{networks.network}</td>
                            <td>{plan.size}</td>
                            <td>₦{parseInt(plan.price.slice(1)) -5}</td>
                            <td>{plan.duration}</td>
                        </tr>
                        ))
                    })}
                    </table>
                </div>
                <div
                className='container-fluid my-4'
                style={{ 
                    backgroundColor: 'white'
                }}
                >
                    <h4 className='text-center'> <b>CABLES</b>  </h4>
                    <h4 className='text-center bold'> <b>Cable IDs </b></h4>
                    <table className='container-fluid'>
                        <thead>
                        <th> Cable </th>
                        <th> Cable ID </th>
                        </thead>
                        <tbody>
                        { cables &&
                        cables.map((cable, index) => (
                            <tr
                            index={index}
                            >
                        <td>{cable.cable}</td> 
                        <td> {cable.id} </td>
                            </tr>
                        ))
                    }
                        </tbody>
                    </table>

                    <h4 className='text-center'> <b>Cable Plans</b> </h4>
                    <table className='container-fluid'>
                        <thead>
                        <th> plan ID </th>
                        <th> Cable</th>
                        <th> plan name </th>
                        <th> price</th>
                        <th> Validity</th>
                        </thead>
                        <tbody>
                        {  cablePlans &&
                        cablePlans.map((plans, index) => {

                            return  (
                                <tr> 
                                    <td>
                                        {plans.cable_id}
                                        </td>
                                        <td>
                                        {plans.type}
                                        </td>
                                        <td>
                                        {plans.plan}
                                        </td>
                                        <td>
                                        {plans.amount}
                                        </td>
                                        <td>
                                        {plans.validity}
                                        </td>
                                </tr>
                            )
                        })
                    }
                        </tbody>
                    </table>
                </div>

                <div
                className='container-fluid my-4'
                style={{ 
                    backgroundColor: 'white'
                }}
                >
                    <h4 className='text-center'> <b>ELECTRICITY</b>  </h4>
                    <table className='container-fluid'>
                        <thead>
                        <th> Disco Name </th>
                        <th> Disco ID</th>
                        </thead>
                        <tbody>
                        {  discoPlans &&
                        discoPlans.map((plan) => (
                                <tr> 
                                    <td>
                                        {plan.discoName}
                                        </td>
                                        <td>
                                        {plan.discoId}
                                        </td>
                                </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

  )
}

export default UserToken