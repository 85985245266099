export const services = [
    {
        name: 'Buy Airtime',
        type: 'airtime',
        image: require('./image/a.png'),
        location: '/buyAirtime',
    },
    {
        name: 'Buy Data',
        type: 'data',
        image: require('./image/d.png'),
        location: '/buyData',
    },
    {
        name: 'Buy Data Card',
        type: 'dataCard',
        image: require('./image/d.png'),
        location: '/buyDataCard',
    },
    {
        name: 'Cables',
        type: 'cable',
        image: require('./image/c.png'),
        location: '/buyCable',
    },
    {
        name: 'Electricity Bill',
        type: 'electricity',
        image: require('./image/bulb.png'),
        location: '/buyElectricity',
    },
    {
        name: 'SMS',
        type: 'sms',
        image: require('./image/sms.png'),
        location: '#',
    },
    {
        name: 'Scratch Cards',
        type: 'examCard',
        image: require('./image/e.png'),
        location: '/buyExamCard',
    },
    {
        name: 'Airtime To Cash',
        type: 'artimeToCash',
        image: require('./image/a2c.png'),
        location: '/airtimetocash',
    },
    {
        name: 'Transfer Cash',
        type: 'transfer',
        image: require('./image/a2c.png'),
        location: '#',
    },
    {
        name: 'Agent',
        type: 'agent',
        image: require('./image/agent.png'),
        location: '/agent',
    },
    {
        name: 'Refer Freinds',
        type: 'refer',
        image: require('./image/referral.png'),
        location: '#',
        ref: 'refer'
    },
]