import React, {useState} from 'react'
const BuyMeterToken = () => {
    const [requestLanguage, setRequestLanguage] = useState('PHP');
  return (
    <div
    style={{  }}
    >
        <h3>Buy Electricity Meter Token </h3>
        <p className='p-2 doc-content card'>
            <h 
            className='d-flex align-items-center text-muted me-2'
            style={{ 
                fontWeight: 'bold'
             }}
            >EndPoint URL </h>
            {/* <hr /> */}
            <input 
            className='form-control'
            readOnly
            type='text' 
            value='https://www.uxtech.com.ng/uxtech-api/v1/electricity'
            
            />
        </p>

        <div
        className='p-2 doc-content card'
        >
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`AUTHORIZATION (Bearer Token)`}
             </h>

            <p>
                <h 
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                 > 
                    Key: 
                </h>

                <t> Authorization </t>
            </p>
            <p>
                <h
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                > 
                    value: 
                </h>
                <t> Bearer apikey************</t>
            </p>
        </div>

        <div className='p-2 doc-content card'>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`REQUIRED PARAMETERS`}
             </h>

            <table>
                <tr>
                    <th
                    className='pe-4'
                    style={{ 
                        fontWeight: 700,
                    }}
                    > 
                        key: 
                    </th>

                    <th
                    style={{ 
                        fontWeight: 700,
                    }}
                    > value
                    </th>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        disco_name: 
                    </td>
                    <td> check from doc page. </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        meter_number: 
                    </td>
                    <td> 644442778532346 </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        meter_type: 
                    </td>
                    <td> prepaid or postpaid </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        amount: 
                    </td>
                    <td> amount to purchase. minimum of 1000 </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        phone: 
                    </td>
                    <td> user phone number e.g 0813482****. </td>
                </tr>
            </table>

        </div> 
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`Sample Payload (Raw Json)`}
             </h>
             <div> 
                <img src='images/api/electricity_sample_payload.png' width={'100%'} height={'100%'} />
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Request
             </h>

             <div>
                <span className='d-flex my-2'>
                    <button 
                    className={ 
                            `btn btn-light btn-lg mx-2 ${requestLanguage == 'PHP' && 'active'}`
                          }
                    onClick={() => setRequestLanguage('PHP')}
                    style={{ 
                        width: '100px'
                     }}
                    >
                             PHP 
                    </button> 
                    <button 
                    bordered
                    className={ 
                        `btn btn-light btn-lg mx-2 ${requestLanguage == 'NODEJS' && 'active'}`
                        }
                    onClick={() => setRequestLanguage('NODEJS')} 
                    style={{ 
                        width: '100px'
                     }}
                    > 
                        NodeJS
                    </button>
                </span> 
                <div>
                    {
                        requestLanguage == 'PHP' ? 
                        <img src='images/api/electricity_sample_request.png' width={'100%'} height={'100%'} />
                        :
                        <img src='images/api/airtime_nodejs_request_sample.png' width={'100%'} height={'100%'} />
                    }
                </div>
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Response
             </h>
             <div>
                    <img src='images/api/electricity_sample_response.png' width={'100%'} height={'100%'} />
                        
             </div>

        </div>
    </div>
  )
}

export default BuyMeterToken