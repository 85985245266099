import React, { useEffect, useState } from 'react'
// import "./css/style.css";
import { getNetworks } from '../API';
const BuyAirtime = () => {

    const [requestLanguage, setRequestLanguage] = useState('PHP');
    const [networks, setNetworks] = useState(null);

    const fetchNetworks = async ()=> {
        const response = await getNetworks();
        console.log(response);
        setNetworks(response);
    }

    useEffect(() => {
        fetchNetworks()
    }, [])
  return (
    <div
    >
        <h3>Buy Airtime</h3>
        <div
        className='doc-content my-4 p-2 card'
        style={{ 
            backgroundColor: 'white'
        }}
        >
            <h4 className='text-muted'> <b>NETWORKS</b>  </h4>
            <table className='container'>
                <thead  >
                    <th> Network Name</th>
                    <th> Network ID</th>
                </thead>
            <tbody>
                {
                    networks &&
                    networks.map((network, index) => (
                        <tr>
                            <td>
                                {network.network}
                            </td>
                            <td>
                                {network.id}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
            </table>

        </div>
        <div 
        className='p-2 doc-content card'
        
        >
            <h 
            className='d-flex align-items-center text-muted me-2'
            style={{ 
                fontWeight: 'bold'
             }}
            >EndPoint URL </h>
            {/* <hr /> */}
            <input 
            className='form-control'
            readOnly
            type='text' 
            value='https://www.uxtech.com.ng/uxtech-api/v1/airtime'
            
            />
        </div>

        <div
        className='p-2 doc-content card'
        >
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`AUTHORIZATION (Bearer Token)`}
             </h>

            <p>
                <h 
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                 > 
                    Key: 
                </h>

                <t> Authorization </t>
            </p>
            <p>
                <h
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                > 
                    value: 
                </h>
                <t> Bearer apikey************</t>
            </p>
        </div>

        <div className='mb-4 p-2 doc-content card'>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`REQUIRED PARAMETERS`}
             </h>

            <table>
                <tr>
                    <th
                    className='pe-4'
                    style={{ 
                        fontWeight: 700,
                    }}
                    > 
                        key: 
                    </th>

                    <th
                    style={{ 
                        fontWeight: 700,
                    }}
                    > value
                    </th>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        network_id: 
                    </td>
                    <td> check network id from "NETWORKS". </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        phone: 
                    </td>
                    <td> phone number to topup e.g 0813482****. </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        amount: 
                    </td>
                    <td> from 50 naira upward. </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        type: 
                    </td>
                    <td> VTU </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        ported: 
                    </td>
                    <td> true or false </td>
                </tr>
            </table>

        </div>
        <div className='mb-4 '>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`Sample Payload (Raw Json)`}
             </h>
             <div> 
                <img src='images/api/airtime_payload.png' width={'100%'} height={'100%'} />
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Request
             </h>

             <div>
                <span className='d-flex my-2'>
                    <button 
                    className={ 
                            `btn btn-light btn-lg mx-2 ${requestLanguage == 'PHP' && 'active'}`
                          }
                    onClick={() => setRequestLanguage('PHP')}
                    style={{ 
                        width: '100px'
                     }}
                    >
                             PHP 
                    </button> 
                    <button 
                    bordered
                    className={ 
                        `btn btn-light btn-lg mx-2 ${requestLanguage == 'NODEJS' && 'active'}`
                        }
                    onClick={() => setRequestLanguage('NODEJS')} 
                    style={{ 
                        width: '100px'
                     }}
                    > 
                        NodeJS
                    </button>
                </span> 
                <div>
                    {
                        requestLanguage == 'PHP' ? 
                        <img src='images/api/airtime_sample_request.png' width={'100%'} height={'100%'} />
                        :
                        <img src='images/api/airtime_nodejs_request_sample.png' width={'100%'} height={'100%'} />
                    }
                </div>
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Response
             </h>
             <div>
                    <img src='images/api/airtime_sample_response.png' width={'100%'} height={'100%'} />
                        
             </div>

        </div>
    </div>
  )
}

export default BuyAirtime