import React from 'react'

const Cable = ({props}) => {
  return (
    <>
    <tbody>
              <tr>
                <th>
                  Reference
                </th>
                <td>
                  {`${props.data.transaction_id}`}
                </td>
              </tr>
              <tr>
                <th>
                  Customer
                </th>
                <td>
                  {props.data.name}
                </td>
              </tr>
              <tr>
                <th>
                  Transaction type
                </th>
                <td>
                  {props.data.type}
                </td>
              </tr>
              <tr>
                <th>
                  Cable Name
                </th>
                <td>
                  {props.data.cable_name}
                </td>
              </tr>
              <tr>
                <th>
                  Cable Plan/Id
                </th>
                <td>
                  {props.data.cable_plan ? props.data.cable_plan : props.data.cable_id}
                </td>
              </tr>
              <tr>
                <th>
                  IUC/Card Name
                </th>
                <td>
                  {props.data.iuc_name}
                </td>
              </tr>
              <tr>
                <th>
                  IUC/Card Number
                </th>
                <td>
                  {props.data.iuc_number}
                </td>
              </tr>
              <tr>
                <th>
                  Price
                </th>
                <td>
                  {props.data.price}
                </td>
              </tr>
              <tr>
                <th>
                  User Phone No.
                </th>
                <td>
                  {props.data.phone}
                </td>
              </tr>
              <tr>
                <th>
                  Status
                </th>
                <td>
                  {props.data.status}
                </td>
              </tr>
              <tr>
                <th>
                  Date
                </th>
                <td>
                  {
                    
                  props.data.date ?
                  props.data.date.split(" ")[0] + " " + props.data.date.split(" ")[1] :
                  props.data.created_at.split(" ")[0] + " " + props.data.date.split(" ")[1]
                  }
                </td>
              </tr>
              <tr>
                <th>
                  Previous Balance
                </th>
                <td>
                  {props.data.oldBallance}
                </td>
              </tr>
              <tr>
                <th>
                  Current Balance
                </th>
                <td>
                  {props.data.walletBallance}
                </td>
              </tr>
              
            </tbody>
    </>
  )
}

export default Cable