import axios from "axios";
import { useContext } from "react";
import DataContext from "./DataContext";
import {getUser} from './DataContext';

const Axios = axios.create({
    // baseURL: 'http://127.0.0.1:8000' // Local Server
    baseURL: 'https://uxtech.com.ng/uxtech-api' // Live server
    // baseURL: 'https://uxtech-api.herokuapp.com' // Old Live server
    
})
// const fetchAPl = async () =>{
 
//     try {
//         const response = await fetch('https://uxtech-api.herokuapp.com/api/register?dataPlans');

//         if(!response.ok) throw Error('Network Error');
//         const res =  await response.json();
//         // console.log(res);
//         // setData(res);
//         // const resp = JSON.stringify(res);
//         return res;
//     } catch (err) {
//         console.log(Error.message);
//     }finally {
//         // setLoading(false);
//     }
// }

//  export const login = async (url, data) =>{
 
//     try {
//         const response = await fetch(url,{
//             method: 'post',
//             headers: {'Content-Type':'application/json'},
//             body: JSON.stringify(data)
            
//         });
//         if(!response.ok) throw Error('Network error!');
//         const res =  await response.json();
//         // console.log(res);
//         // setUser(res);
//         // const resp = JSON.stringify(res);
//         return res;
//     } catch (Error) {
//         console.log(Error);
//         return Error
//     }finally {
//         // setLoading(false);
//     }
// }
// const {getUser} = useContext(DataContext);
// const user = getUser('user');


export const createBankAccounts = async (data) => {
    try {
       const response = await Axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            url: '/api?createBankAccounts',
            data
        });

        return response.data;
    } catch (error) {

        return error.response;
    }
}
export const validateMeter = async (data) => {
    try {
       const response = await Axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            url: '/api?validate',
            data
        });

        return response.data;
    } catch (error) {

        return error.response;
    }
}
export const discoPlans = async () => {
    const user = getUser('user');
    try{
        const response = await Axios.get('/api?discoPlans',{
            headers: {
                "Authorization": "Bearer "+user.token
            }
        });

        if (response.status === 401 ) throw Error("User is not authenticated or session has expired");
        return response.data;
    }catch(Error){
        // console.log(Error.response);
        return Error.response;
    }
}
export const buyDataCard = async (data) => {
    const user = getUser('user');
    try {
        const response = await Axios.post('/api?buyDataCard', data, {
            headers: {
                'Authorization': "Bearer "+user.token
            }
        });
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error.response);
        return Error.response.data;
        
        
    }
}
export const getDataCardPlans = async () => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?dataCardPlans', {
            headers: {
                'Authorization': "Bearer "+user.token
            }
        });
        if(response.status !== 200) throw Error('Error!')
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error.response);
        return Error;
        
    }
}
export const getAPIDataPlans = async () => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?plans');
        if(response.status !== 200) throw Error('Error!') 
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const getToken = async () => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?getToken', {
            headers: {
                'Authorization': "Bearer "+ user.token,
            }
        });
        if(response.status !== 200) throw Error('Error!')
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error.response);
        return Error;
        
    }
}
export const createToken = async () => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?createToken', {
            headers: {
                'Authorization': "Bearer "+ user.token,
            }
            });
        if(response.status !== 200) throw Error('Error!')
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const getNetworkInitials = async (url, data) => {
    
    try {
        const response = await Axios.get('/api?networkInitials');
        // console.log(response);
        return response.data;
    } catch (e) {
        // console.log(Error);
        // return e.response.data;
        
    }
}

// Cable Services
export const verifyIUC = async (data) => {
    const user = getUser('user');
    // console.log(user);
    try {
        const response = await Axios.post('/api?verifyIUC', data, {
            headers: {
                "Authorization": `Bearer ${user.token}`
            }
        });
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const buyCable = async (data) => {
    const user = getUser('user');
    try {
        const response = await Axios.post('/api?buyCable', data, {
            headers: {
                "Authorization": `Bearer ${user.token}`
            }
        });
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const getCables = async (url, data) => {
    
    try {
        const response = await Axios.get('/api?cables');
        if(response.status !== 200) throw Error('Network Error!')
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}

export const getAirtime = async (url, data) => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?getAirtime');
        // console.log(response);
        return response.data;
    } catch (e) {
        // console.log(e.response.data);
        return e.response.data;
        
    }
}
export const getPlans = async (url, data) => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?plans');
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const getNetworks = async (url, data) => {
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?networkProviders', {
            headers:{
                "Authorization": `Bearer ${user.token}`
            }
        });
        // console.log(response);
        return response.data;
    } catch (Error) {
        // console.log(Error);
        return Error;
        
    }
}
export const login = async (data) => {
    
    try {
        const response = await Axios.post('/api?login', data,)
        // console.log(response);
        return response.data;
    } catch (e) {
        // console.log(e.status)
        // if(e.response.status == 500){
        //     console.log("server error")
        // }
        return e;
        
    }
}

export const RegisterUser = async ( data) =>{
 
    try {
        const response = await Axios.post('/api?testReg', data);
        return response.data;
    } catch (e) {
        // console.log(e.status);
        return e
    }
}



export const getTransactions = async () =>{
    const user = getUser('user');
    try {
       const response = await Axios.get('/api?transactionHistory', {
        headers: {
            "Authorization": `Bearer ${user.token}`
        }
       });

       return response.data
    } catch (e) {
        // Action to do when unable to get transaction histories
        // Check for authorize request or things similar.
        // return e;

    }
}

export const userData = async () =>{
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?userData', {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        });
        return  response.data;
    } catch (e) {
    
        // console.log(e);
        // console.log(user.token);
        return e
        // return {status:false, Error}

    }

}

export const buyData = async (data) =>{
    const user = getUser('user');
    try {

        const response = await Axios.post("/api?buyData", data,{
            headers: {'Content-Type':'application/json',
                        'Authorization': 'Bearer '+user.token
            }
        });
        
        return response.data;
       
    } catch (Error) {
        // console.log(Error);
        if(Error.response.status == 500){
            return {
                message: 'Request aborted. Please check transaction history before trying again to avoid double purchase.'
            }
        }else{
            return Error.response.data;
        }
        
    }
}

export const buyAirtime = async (data) =>{
    const user = getUser('user');
    try {

        const response = await Axios.post("/api?buyAirtime", data,{
            headers: {'Content-Type':'application/json',
                        'Authorization': 'Bearer '+user.token
            }
        });
        // console.log(response.data);
        return response.data;
       
    } catch (e) {
        
        return e
        
    }
}

export const buyElectricityApi = async (data) =>{
    const user = getUser('user');
    try {
       const response = await Axios.post("/api?buyElectricity", data,{
        headers: {
            'Authorization': "Bearer "+user.token,
        }
       })
       if(response.status === 200){
            const res =   response.data;
            
            return res;
        }else if(response.status === 401){
            return {message: "User Session has Expired. Please Login again!",
                    status: 401
                    }
        }
        else{
            throw Error('Network Error.  Check your network and try again.');
        } 
    } catch (Error) {
        // console.log(Error.response);
        return Error.response.data;
    }
}

export const resetPassword = async (data) =>{
 
    try {
            const response = await Axios.post('/api?forgotPassword', data);
            // console.log(response);
            return response.data;
    } catch (e) {
        // console.log(e)
        return e
        // console.log(Error.message);
    }
}

export const setNewPassword = async (data) =>{
 
    try {
        const response = await Axios.post('/api?resetPassword', data);
        return response.data;
    } catch (Error) {
        return Error
        // console.log(Error.message);
    }
}
export const ChangePassword = async (data) =>{
    const user = getUser('user');
    try {
        const response = await Axios.post('/api?changePassword', data, {
         headers: {
             "Authorization": `Bearer ${user.token}`
         }
        });
 
        return response.data
     } catch (e) {
         // Action to do when unable to get transaction histories
         // Check for authorize request or things similar.
         return e.response.data;
 
     }
}

export const activateAgent = async () =>{
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?activateAgent', {
         headers: {
             "Authorization": `Bearer ${user.token}`
         }
        });
 
        return response.data
     } catch (e) {
         return e.response.data;
 
     }
}

export const updateProfile = async (data) =>{
    const user = getUser('user');
    try {
        const response = await Axios.post('/api?updateProfile', data,{
            headers: {
                "Authorization": `Bearer ${user.token}`
            }
        });
        return response.data;

        // const response = await fetch("https://uxtech-api.herokuapp.com/api/update",{
        //     method: 'post',
        //     headers: {'Content-Type':'application/json',
        //                 'Authorization': 'Bearer '+user.token
        //             },
        //     body: JSON.stringify(data)
            
        // });
        // if(!response.ok) throw Error('Network error');
        // const res =  await response.json();
        // return res;

    } catch (e) {
        return e.response.data;
        // console.log(Error.message);
    }
}

export const walletHistory = async (data) =>{
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?walletHistory', {
         headers: {
             "Authorization": `Bearer ${user.token}`
         }
        });
 
        return response.data
     } catch (e) {
         // Action to do when unable to get transaction histories
         // Check for authorize request or things similar.
         // return e;
 
     }
}

export const plans = async (data) =>{
    const user = getUser('user');
    try {
        const response = await Axios.get('/api?plans', {
            headers: {'Content-Type':'application/json',
                        'Authorization': 'Bearer '+user.token    
                },
        });
        return response.data;
    } catch (Error) {
        return Error
        // console.log(Error.status);
    }
}

export const testPayment = async () =>{
 
    try {
        const response = await fetch("https://uxtech-api.herokuapp.comhttps://sandbox.monnify.com/api/v1/merchant/transactions/init-transaction",{
            method: 'post',
            headers: {'Content-Type':'application/json',
                    'Authorization': 'Basic TUtfVEVTVF9DSFZRRlJBN1NHOjZRSE1aMlBMQ1VHVDJUQzlNUFBEWTRXSFhQRERDWlBK'
                        },
            body: JSON.stringify(
                {
                    amount: 100.00,
                    customerName: "Stephen Ikhane",
                    customerEmail: "stephen@ikhane.com",
                    paymentReference: "123031klsaecUI",
                    paymentDescription: "Trial transaction",
                    currencyCode: "NGN",
                    contractCode: "6146592431",
                    redirectUrl: "https://my-merchants-page.com/transaction/confirm",
                    paymentMethods: "ACCOUNT_TRANSFER"
                }
            )
            
        });
        if(!response.ok) throw Error('Network Error');
        const res =  await response.json();
        // console.log(res);
        // setUser(res);
        // const resp = JSON.stringify(res);
        return res;
    } catch (Error) {
        return Error
        // console.log(Error.message);
    }
}
export const testAxios = async () =>{
 
    try {
        const response = Axios.get('/api?testAxios');

        return response;
    } catch (e) {

        // console.log(e.status);
        return e;
    }
}
