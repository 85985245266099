import React, {useState, useEffect} from 'react'
import { getNetworks, getAPIDataPlans } from '../API';
import MyTable from './components/Table';

const BuyData = () => {
    const [requestLanguage, setRequestLanguage] = useState('PHP');
    const [networks, setNetworks] = useState(null);
    const [plans, setPlans] = useState(null);

    const fetchNetworks = async ()=> {
        const response = await getNetworks();
        // console.log(response);
        setNetworks(response);
    }

    const setDataPlans = async () => {
        const response = await getAPIDataPlans();
        setPlans(response);
        // console.log(plans);
    }

    const columns = [
        // {
        // accessor: `transaction_id`,
        // Header: 'Ref',
        // },
        {
            accessor: `network`,
            Header: 'Network',
            Cell: (props) => {
                return (
                    props.row.original.prices.map((d) => <h6>{props.row.original.network.split(' ')[0]}</h6>)
                )
            }
        },
        {
        accessor: 'planCode',
        Header: 'Plan Id',
        Cell: (props) => {
            const { prices} = props.row.original;
            return (
                prices.map( (price) =>(<h6>{price.planCode}</h6>)) 
            );
        },
        },
       
        {
        accessor: `planType`,
        Header: 'Plan Type',
        Cell: (props) => {
            return (
                props.row.original.prices.map((d) => <h6>{props.row.original.network}</h6>)
            )
        }
        },
        {
        accessor: `price`,
        Header: 'Size', 
        Cell: (props) => {
            const { prices} = props.row.original;
            return (
                prices.map( (price) =>(<h6>{price.size}</h6>)) 
            );
        },
        },
        {
            accessor: `prices`,
            Header: 'Price', 
            Cell: (props) => {
                const { prices} = props.row.original;
                return (
                    prices.map( (price) =>(<h6>₦{price.price.split('₦')[1] - price.discount}</h6>)) 
                );
            },
        },
        {
        accessor: `duration`,
        Header: 'Duration',
        Cell: (props) => {
            const { prices} = props.row.original;
            return (
                prices.map( (price) =>(<h6>{price.duration}</h6>)) 
            );
        },
        },
    
      ]
      
        // const data = useMemo(() => userTransactions, [userTransactions]);

    useEffect(() => {
        fetchNetworks();
        setDataPlans();
    }, [])
  return (
    <div
    style={{  }}
    >
        <h3>Buy Data </h3>
        <div
        className='doc-content my-4 p-2 card'
        style={{ 
            backgroundColor: 'white'
        }}
        >
            <h4 className='text-muted'> <b>NETWORKS</b>  </h4>
            <table className='container'>
                <thead  >
                    <th> Network Name</th>
                    <th> Network ID</th>
                </thead>
            <tbody>
                {
                    networks &&
                    networks.map((network, index) => (
                        <tr>
                            <td>
                                {network.network}
                            </td>
                            <td>
                                {network.id}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
            </table>

        </div>
        <div
        className='doc-content card my-4 p-2'
        style={{ 
            backgroundColor: 'white'
        }}
        >
            <h4 className='text-muted'> <b>DATA PLANS</b> </h4>
            {plans !== null && <MyTable columns={columns} body={plans} />}
            {/* <table className='container'>
                <thead  >
                    <th> Plan Id</th>
                    <th> Network</th>
                    <th> plan Type</th>
                    <th> Size </th>
                    <th> Price </th>
                    <th> Duration </th>
                </thead>
            {plans && plans.map((networks, i) => {
                // i === 0 && console.log(networks);
                let plans = Array.from(networks.prices);
                // i=== 0 && console.log(plans);
            return  plans.map((plan, index) => (
                    <tr key={index}>
                    <td>{plan.planCode}</td>
                    <td>{networks.network.split(" ")[0]}</td>
                    <td>{networks.network}</td>
                    <td>{plan.size}</td>
                    <td>₦{parseInt(plan.price.slice(1)) -5}</td>
                    <td>{plan.duration}</td>
                </tr>
                ))
            })}
            </table> */}
        </div>
        <div className='p-2 doc-content card'>
            <h 
            className='d-flex align-items-center text-muted me-2'
            style={{ 
                fontWeight: 'bold'
             }}
            >EndPoint URL </h>
            {/* <hr /> */}
            <input 
            className='form-control'
            readOnly
            type='text' 
            value='https://www.uxtech.com.ng/uxtech-api/v1/data'
            
            />
        </div>

        <div
        className='p-2 doc-content card'
        >
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`AUTHORIZATION (Bearer Token)`}
             </h>

            <p>
                <h 
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                 > 
                    Key: 
                </h>

                <t> Authorization </t>
            </p>
            <p>
                <h
                className='me-4'
                style={{ 
                    fontWeight: 700,
                 }}
                > 
                    value: 
                </h>
                <t> Bearer apikey************</t>
            </p>
        </div>

        <div className='p-2 doc-content card'>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`REQUIRED PARAMETERS`}
             </h>

            <table>
                <tr>
                    <th
                    className='pe-4'
                    style={{ 
                        fontWeight: 700,
                    }}
                    > 
                        key: 
                    </th>

                    <th
                    style={{ 
                        fontWeight: 700,
                    }}
                    > value
                    </th>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        network_id: 
                    </td>
                    <td> check network IDs from "NETWORKS" </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        plan_id: 
                    </td>
                    <td> check plan id from "DATA PLANS". </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        phone: 
                    </td>
                    <td> phone number to topup e.g 0813482****. </td>
                </tr>
                <tr>
                    <td
                    className='pe-4'
                    > 
                        ported: 
                    </td>
                    <td> true or false </td>
                </tr>
            </table>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 15,
                fontWeight: 900
             }}
             className='text-muted'
             >
                {`Sample Payload (Raw Json)`}
             </h>
             <div> 
                <img src='images/api/data_sample_payload.png' width={'100%'} height={'100%'} />
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Request
             </h>

             <div>
                <span className='d-flex my-2'>
                    <button 
                    className={ 
                            `btn btn-light btn-lg mx-2 ${requestLanguage == 'PHP' && 'active'}`
                          }
                    onClick={() => setRequestLanguage('PHP')}
                    style={{ 
                        width: '100px'
                     }}
                    >
                             PHP 
                    </button> 
                    <button 
                    bordered
                    className={ 
                        `btn btn-light btn-lg mx-2 ${requestLanguage == 'NODEJS' && 'active'}`
                        }
                    onClick={() => setRequestLanguage('NODEJS')} 
                    style={{ 
                        width: '100px'
                     }}
                    > 
                        NodeJS
                    </button>
                </span> 
                <div>
                    {
                        requestLanguage == 'PHP' ? 
                        <img src='images/api/data_sample_request.png' width={'100%'} height={'100%'} />
                        :
                        <img src='images/api/airtime_nodejs_request_sample.png' width={'100%'} height={'100%'} />
                    }
                </div>
             </div>

        </div>
        <div className='mb-4'>
            <h 
            style={{ 
                fontSize: 17,
                fontWeight: 900
             }}
             className='text-muted'
             >
                Sample Response
             </h>
             <div>
                    <img src='images/api/data_sample_response.png' width={'100%'} height={'100%'} />
                        
             </div>

        </div>
    </div>
  )
}

export default BuyData