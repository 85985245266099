import React from 'react'

const Data = ({props}) => {
  return (
    <>
    <tbody>
              <tr>
                <th>
                  Reference
                </th>
                <td>
                  {`${props.data.transaction_id}`}
                </td>
              </tr>
              <tr>
                <th>
                  Customer
                </th>
                <td>
                  {props.data.name}
                </td>
              </tr>
              <tr>
                <th>
                  Transaction type
                </th>
                <td>
                  {props.data.type}
                </td>
              </tr>
              {
                
              props.data.type !== 'AIRTIME' ? 
              // When transaction type is AIRTIME
                <tr>
                <th>
                  Size
                </th>
                <td>
                  {props.data.size}
                </td>
              </tr>
              : 
              // When transaction type is Data
              <tr>
                <th>
                  Amount Purchased
                </th>
                <td>
                  {props.data.size}
                </td>
              </tr>
              }
              {
                props.data.type == 'DATA CARD' &&
                <tr>
                <th>
                  Quantity
                </th>
                <td>
                  {  props.data.quantity}
                </td>
              </tr>
              }
              <tr>
                <th>
                  Price
                </th>
                <td>
                  {props.data.price}
                </td>
              </tr>
              <tr>
                <th>
                  {props.data.type == 'DATA CARD'? "Pin(s)" : "Phone"} 
                </th>
                <td>
                  { props.data.type == 'DATA CARD'? props.data.pin : props.data.phone}
                </td>
              </tr>
              <tr>
                <th>
                {props.data.type === 'TOKEN'? 'Token' : 'Network'}
                </th>
                <td>
                {props.data.type === 'TOKEN'? props.data.token :  props.data.network}
                </td>
              </tr>
              {-
                props.data.type == 'TOKEN' &&
                <>
                <tr>
                <th>
                  Meter Number
                </th>
                <td>
                  {  props.data.meter_number}
                </td>
                </tr>
                <tr>
                <th>
                  Meter Name
                </th>
                <td>
                  {  props.data.meter_name}
                </td>
                </tr>
                </>
              }
              <tr>
                <th>
                  Status
                </th>
                <td>
                  {props.data.status}
                </td>
              </tr>
              <tr>
                <th>
                  Date
                </th>
                <td>
                  {
                    
                  props.data.date ?
                  props.data.date.split(" ")[0] + " " + props.data.date.split(" ")[1] :
                  props.data.created_at.split(" ")[0] + " " + props.data.date.split(" ")[1]
                  }
                </td>
              </tr>
              <tr>
                <th>
                  Previous Balance
                </th>
                <td>
                  {props.data.oldBallance}
                </td>
              </tr>
              <tr>
                <th>
                  Current Balance
                </th>
                <td>
                  {props.data.walletBallance}
                </td>
              </tr>
              
            </tbody>
    </>
  )
}

export default Data