import React, { useState } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'
import Data from './transactions/data';
import Cable from './transactions/cable';


export function Receipt(props ) {
  // console.log(props);
    return  props.data && (
      <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter"  >
            
          <h5 
          style={{ margin: '0px' }}
          >
              <img src={require('./image/logo.png')}/> 
              <b > TRANSACTION DETAILS </b>
          </h5>
          
          <h6 
            className='text-muted'
            style={{ margin: '0px' }}
            > <small>www.uxtech.ng </small></h6>
          </Modal.Title>
          
        </Modal.Header>
        
        <Modal.Body>

          <Table>
            <thead>
            </thead>
            {props.data.type == "DATA" && <Data props={props} />}
            {props.data.type == "AIRTIME" && <Data props={props} />}
            {props.data.type == "TOKEN" && <Data props={props} />}
            {props.data.type == "DATA CARD" && <Data props={props} />}
            {props.data.type == "CABLE" && <Cable props={props} />}
          </Table>
          <b className='text-muted'>
           contact us on whatsapp for any complain.
           08138282448
          </b>
         
        </Modal.Body>
        <Modal.Footer>{
          props.type !== 'purchase'? 
          <>
          <Button variant='dark' onClick={window.print}>Print</Button>
          <Button variant='dark' onClick={props.onHide}>Close</Button>
          </>
           :
           <>
           <Button variant='dark'
           className='float-start'
            onClick={props.onHide}>Goto Dashboard</Button>
          
          <Button variant='dark' 
          className='float-start'
          onClick={props.onClose}>Buy Again</Button>
          {/* <p/><Button variant='dark' onClick={window.print}>Print</Button> */}
          </>
          
          }
        
        </Modal.Footer>
      </Modal>
    );
  }

export const OldReceipt = ({status}) => {
  
    const [show, setShow] = React.useState(status);
//     const [show, setShow] = useState(false)
//     const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
  return (

    <>
    {/* <Button variant="primary" onClick={() => setModalShow(true)}>
      Launch vertically centered modal
    </Button> */}

    <Receipt
      show={show}
      onHide={() => setShow(false)}
    />
  </>

    // <div>
    //       <Button variant="primary" onClick={handleShow}>
    //     Launch demo modal
    //   </Button>

    //   <Modal show={show} onHide={handleClose}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>Modal heading</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={handleClose}>
    //         Close
    //       </Button>
    //       <Button variant="primary" onClick={handleClose}>
    //         Save Changes
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    // </div>
  )
}
